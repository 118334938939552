<script setup lang="ts">
import { computed } from "vue";
import type { Tracker } from "@/stores/fileStore";
import { filesize } from "filesize";

import UploadProgress from "@/components/files/UploadList/UploadProgress.vue";
import FileStatusPill from "@/components/files/UploadList/FileStatusPill.vue";

const props = defineProps<{
  tracker: Tracker;
}>();

defineEmits<{
  (event: "remove"): void;
}>();

const fileSize = computed(() => {
  return filesize(props.tracker.fileSize);
});
const isUploadOngoing = computed(() => props.tracker.progressDisplay < 100);
const isConversionDone = computed(
  () => props.tracker.conversionStatus === "done"
);
const isConversionOngoing = computed(
  () =>
    props.tracker.conversionStatus === "inprogress" ||
    props.tracker.conversionStatus === "waiting"
);
</script>

<template>
  <li class="d-inline-flex justify-content-between align-items-center py-1 row">
    <div class="col-8 row">
      <div class="col-12 d-flex align-items-center gap-1 row">
        <el-icon size="1rem" class="text-dark col-auto"
          ><i class="bi bi-image-fill"></i
        ></el-icon>
        <div class="col p-0 text-truncate">
          {{ tracker.fileName }}
        </div>
        <div class="col-auto p-0 text-secondary" style="font-size: 0.6rem">
          {{ fileSize }}
        </div>
      </div>

      <div class="col-12">
        <UploadProgress
          :value="tracker.progressDisplay"
          :animated="isConversionOngoing"
          :theme="
            isConversionOngoing
              ? 'warning'
              : isConversionDone
              ? 'success'
              : undefined
          "
        />
      </div>
    </div>

    <div class="col align-items-center justify-content-end d-flex gap-2">
      <span v-if="tracker.conversionStatus && !isConversionDone" class="me-2">
        <FileStatusPill :status="tracker.conversionStatus" />
      </span>
      <i
        v-if="isConversionDone"
        class="bi bi-check-lg h4 text-success d-inline-flex align-items-center m-0"
      ></i>
      <span v-if="isUploadOngoing" class="text-secondary">
        {{ tracker.progressDisplay }}%
      </span>
      <button
        v-if="tracker.conversionStatus === 'failed'"
        class="btn-close"
        @click="$emit('remove')"
      ></button>
    </div>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end"></div>
  </li>
</template>
