<script setup lang="ts">
import { Logger } from "@/logger";
import CanvasSettingsState from "@/stores/CanvasSettingsState";
import Loader from "@/components/Loader.vue";
import { /*nextTick,*/ computed, ref } from "vue";
import { delay } from "lodash";

const isLoading = ref(false);

async function toggleMode() {
  Logger.info(
    `PerfModeToggler : mode on ? ${!CanvasSettingsState.performanceModeOn}`
  );
  isLoading.value = true;
  /* FIXME : nextTick does not work, Loader does not show
    before toggle start*/
  //await nextTick();
  delay(() => {
    CanvasSettingsState.togglePerformanceMode();
    isLoading.value = false;
  }, 200);
}

const icon = computed(() => {
  if (CanvasSettingsState.performanceModeOn) return "lock";
  else return "unlock";
});
</script>
<template>
  <i @click="toggleMode()" :class="`bi bi-${icon}`"></i>
  <Loader :active="isLoading" />
</template>
