export enum LocalStorageKey {
  TRACKING_FILES = "trackingFiles",
  DONT_SHOW_BROWSER_ALERT = "browser-alert:dont-show",
  BROWSER_ALERT_CLOSE_DATE = "browser-alert:close-date",
  DONT_SHOW_INSTALL_ALERT = "install-alert:dont-show",
  INSTALL_ALERT_CLOSE_DATE = "install-alert:close-date",
}

class LocalStorage {
  setItem(key: LocalStorageKey, value: string) {
    localStorage.setItem(key, value);
  }

  getItem(key: LocalStorageKey): string | null {
    return localStorage.getItem(key);
  }

  removeItem(key: LocalStorageKey) {
    localStorage.removeItem(key);
  }

  setDateItem(key: LocalStorageKey, value: Date) {
    this.setItem(key, value.toISOString());
  }

  getDateItem(key: LocalStorageKey) {
    const value = this.getItem(key);
    return value ? new Date(value) : null;
  }

  setBooleanItem(key: LocalStorageKey, value: boolean) {
    this.setItem(key, value ? "true" : "false");
  }

  getBooleanItem(key: LocalStorageKey): boolean {
    const value = this.getItem(key);
    return value === "true";
  }
}

const $ls = new LocalStorage();

export default $ls;
