<script setup lang="ts">
import { useDrawingStore } from "@/stores/fileStore";
const props = defineProps<{
  drawingid: number;
  selected: boolean;
}>();
const { toggleDrawing } = useDrawingStore();

function toggleSelection() {
  toggleDrawing(props.drawingid);
}
</script>

<template>
  <div class="ps-4 pe-4" @click.stop="toggleSelection">
    <el-checkbox :model-value="selected" @click.stop="toggleSelection" />
  </div>
</template>
