import { Client, Job, File as OdaFile } from "@inweb/client";
import type { CdaTree, CdaTreeElement, DRProperty } from "./domain.types";
import type { Textstyle } from "@/open-cloud/builders/TextStyleBuilder";
import { Logger } from "@/logger";

class OdaRepository extends EventTarget {
  client = new Client({
    serverUrl: import.meta.env.DR_API_URL + import.meta.env.DR_ODA_PATH,
  });
  private _token: string | null = null;

  get token(): string {
    if (!this._token) {
      throw new Error(
        "Trying to access oda_api_token, but it's not initialized yet."
      );
    }
    return this._token;
  }

  set token(token: string) {
    this._token = token;
  }

  safeTimestampToFileName(fileName: string) {
    if (!fileName) return fileName;
    const fileNameNoDots = fileName.replace(/\./g, "_");
    const iso = new Date().toISOString();
    const res = /(.*)\.\d{3}Z$/.exec(iso);
    if (!res) {
      Logger.warn(`could not parse timestamp to make it safe: ${iso}`);
      return fileNameNoDots;
    }
    return `${fileNameNoDots}_${res[1]}`;
  }

  async init(jwt: string) {
    this.token = `Bearer ${jwt}`;
    if (this.token) {
      return this.connectToODA();
    }
  }

  private async connectToODA() {
    const start = performance.now();
    Logger.info("oda.repository.connectServer : start connecting server");
    try {
      await this.client.signInWithToken(this.token);
      this.client.httpClient.headers = { Authorization: this.token };
      Logger.info(
        "oda.repository.connectServer : Connection to the ODA server initiated, User signed in"
      );
    } catch (e) {
      const isNull = this.token === null;
      const isEmpty = this.token === "";
      Logger.error(`token is null ? ${isNull}, token is empty ? ${isEmpty}`);
      if (
        e instanceof Error &&
        e.name === "TypeError" &&
        e.message === "Failed to fetch"
      ) {
        Logger.error(e.message);
        throw e;
      } else if (
        e instanceof Error &&
        e.name === "Error" &&
        e.message === "Unauthorized"
      ) {
        Logger.info("oda.repository.connectServer : Invalid token");
        throw e;
      } else {
        Logger.warn(
          "oda.repository.connectServer : There was a problem connecting to ODA Server"
        );
        throw e;
      }
    }
    const end = performance.now();
    Logger.info(
      `oda.repository.connectServer : done connecting server in ${
        end - start
      } ms`
    );
  }

  async uploadOneDwg(
    file: File,
    onProgress?: (progress: number) => void
  ): Promise<any> {
    const resultFile = await this.client.httpClient
      .uploadFile(`/files`, file, (progress) => {
        this.client.emitEvent({ type: "uploadprogress", data: progress, file });
        onProgress?.(progress);
      })
      .then((xhr: XMLHttpRequest) => JSON.parse(xhr.responseText));
    return resultFile;
  }

  async uploadOneVsfFromBuffer(
    vsf: ArrayBuffer,
    name: string
  ): Promise<OdaFile> {
    const file = new File([vsf], name);
    const odaFile = await this.client.httpClient
      .uploadFile(`/files?noJob=true`, file, (progress) => {
        this.client.emitEvent({ type: "uploadprogress", data: progress, file });
      })
      .then((xhr: XMLHttpRequest) => JSON.parse(xhr.responseText))
      .then((data) => new OdaFile(data, this.client.httpClient));
    return odaFile;
  }

  async getOdaFile(fileData: any) {
    return new OdaFile(fileData, this.client.httpClient);
  }

  downloadVSFFile(revisionFileId: string): Promise<ArrayBuffer> {
    return this.client.downloadFile(revisionFileId);
  }

  downloadDWGFile(fileId: string, resourceId: string): Promise<ArrayBuffer> {
    const file = new OdaFile({ id: fileId }, this.client.httpClient);
    return file.downloadResource(resourceId);
  }

  /**
   * Search and return properties of file using OCS API
   * @param id id of Dwg file to search parameters from on Open Cloud Server
   */

  async fetchProperties(id: string, userId: number): Promise<DRProperty> {
    return {
      id,
      userId,
      fetchedAt: new Date(),
      properties: {
        textstyles: await this.fetchTextstyles(id),
      },
    };
  }

  /**
   * Searches for textstyles properties in initial drawing
   * @param fileId id of Dwg file to search parameters from on Open Cloud Server
   */

  async fetchTextstyles(fileId: string): Promise<Textstyle[]> {
    const file = new OdaFile({ id: fileId }, this.client.httpClient);
    // First search for text entities based on their TextStyleId properties using OCS API
    const searchPattern = {
      key: "TextStyleId",
      value: { $not: { $eq: "" } },
    };

    let handles: string[] = [];
    try {
      handles = (await file.searchProperties(searchPattern)).map(
        (value) => value.handle
      );
    } catch (e) {
      Logger.error(
        `oda.repository.ts : could not search handles with textstyleId pattern`
      );
      throw e;
    }

    if (!handles.length) {
      Logger.warn(
        `oda.repository.fetchTextstyles : there are no entity with "TextStyleId" key in ${fileId}`
      );
      return [];
    }

    // Then fetch cda tree
    let start = performance.now();
    const tree = await this.getTree(file);
    let end = performance.now();
    Logger.info(`oda.repository.getTree took ${end - start} ms`);
    start = performance.now();
    const modelSpace = this.getModelSpaceElements(tree);
    end = performance.now();
    Logger.info(`oda.repository.getModelSpaceElements took ${end - start} ms`);
    start = performance.now();
    const flattenModelSpace = this.flattenCdaTree(modelSpace);
    end = performance.now();
    Logger.info(`oda.repository.flattenCdaTree took ${end - start} ms`);

    const previous = handles.length;
    handles = handles.filter((handle) => {
      return flattenModelSpace.some(
        (spaceElement) => spaceElement.handle === handle
      );
    });
    Logger.info(
      `oda.repository.fetchTextStyles previous handle length : ${previous}, filtered: ${handles.length}`
    );
    // fetch object in handle array
    const propertiesArray: any[] = [];
    if (handles.length > 1000) {
      handles = handles.slice(0, 1000);
    }
    try {
      // have to split in batch of 1000 elements. Otherwise OCS does not respond
      const batchSize = 1000;
      const handleBatches = [];
      while (handles.length > 0) {
        handleBatches.push(handles.splice(0, batchSize));
      }

      for (const batch of handleBatches) {
        const batchProperties = await file.getProperties(batch);
        propertiesArray.push(...batchProperties);
      }
    } catch (e) {
      Logger.error(
        `oda.repository.ts : could not get properties for handles ${handles.length}`
      );
      throw e;
    }

    // filter TextHeights by TextStylesId in properties
    // Map<texstyle name, Map<height, occurences>>
    const textstyles: Map<string, Map<number, number>> = new Map();
    for (const properties of propertiesArray) {
      const name = properties.TextStyleId;
      const height = parseFloat(properties.TextHeight);
      if (name && height) {
        if (textstyles.has(name)) {
          const heights = textstyles.get(name);
          if (heights?.has(height)) {
            let occurences = heights.get(height) || 0;
            occurences++;
            heights.set(height, occurences);
          } else {
            heights?.set(height, 1);
          }
        } else {
          const heights = new Map();
          heights.set(height, 1);
          textstyles.set(name, heights);
        }
      }
    }
    // Only keep TextHeights with most occurences
    const result: Textstyle[] = [];
    for (const [name, heights] of textstyles) {
      let mostOccuringheight = -1;
      let maxOccurences = -1;
      for (const [height, occurences] of heights) {
        if (occurences > maxOccurences) {
          maxOccurences = occurences;
          if (mostOccuringheight != -1) heights.delete(mostOccuringheight);
          mostOccuringheight = height;
        } else {
          heights.delete(height);
        }
      }

      const [firstheight] = heights.keys();
      result.push({
        name: name,
        size: firstheight,
      });
    }
    return result;
  }

  async getTree(file: OdaFile): Promise<CdaTree> {
    try {
      return file.getCdaTree();
    } catch (e) {
      return [];
    }
  }

  getModelSpaceElements(tree: CdaTree): CdaTree {
    if (!tree.length) return [];
    const presentations = tree[0].children.find(
      (element) => element.name == "Model"
    );
    if (!presentations) return [];
    const modelSpace = presentations.children.find(
      (element) => element.name == "Model"
    )?.children;
    if (!modelSpace) return [];
    return modelSpace;
  }

  flattenCdaTree(tree: CdaTree): CdaTree {
    const result: CdaTree = [];

    function traverse(node: CdaTreeElement) {
      result.push(node);
      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          traverse(child);
        }
      }
    }

    for (const node of tree) {
      traverse(node);
    }

    return result;
  }

  async deleteWaitingJobs(): Promise<Job[]> {
    Logger.info(`oda.repository.deleteWaitingJobs : deleting waiting job...`);
    const res = await this.client.getJobs("waiting");
    for (const job of res.result) {
      await this.client.deleteJob(job.id);
      // delete the files that were uploaded
      // await this.client.deleteFile(job.fileId);
      // IMPROVEMENT : there are several jobs that are created and some may be already done
      // especially, properties extraction is fast and is likely done before.
      // created files and folder should be deleted then. This is not a blocker but could improve
      // space management on OCS.
    }
    return res.result;
  }
}

export const odaRepository = new OdaRepository();
