import { ViewerEvents, type DRViewer } from "../DRViewer";

export default class Command<T> {
  _viewer: DRViewer;
  _undostack: T[] = [];
  _redostack: T[] = [];
  constructor(viewer: DRViewer) {
    this._viewer = viewer;
  }
  //
  /**
   * call it to execute the command for first time from other components
   * @param params
   * @param shouldStore flag to store in undo-redo history and trigger drawing edited for saving
   */
  execute(params: T, shouldStore = true) {
    this._execute(params);
    if (shouldStore) {
      this._undostack.push(params);
      this._viewer.commandFactory.history.execute({ command: this });
      this._viewer.emit(new Event(ViewerEvents.DrawingEdited));
    }
  }
  // override these 3 methods depending on Command type
  protected _execute(params: T): any {}
  protected _reexecute(params: T): any {}
  protected _unexecute(params: T): any {}

  // pop last action from undo stack and push it to redo stack
  // call unexecute
  undo() {
    const params = this._undostack.pop();
    if (!params) return;
    this._unexecute(params);
    this._redostack.push(params);
    this._viewer.emit(new Event(ViewerEvents.DrawingEdited));
  }
  // pop last action from redo stack and push it to undo stack
  // call reexecute
  redo() {
    const params = this._redostack.pop();
    if (!params) return;
    this._reexecute(params);
    this._undostack.push(params);
    this._viewer.emit(new Event(ViewerEvents.DrawingEdited));
  }

  // remove oldest command from pile
  shiftUndo() {
    const oldest = this._undostack.shift();
    if (oldest) this._delete(oldest);
  }
  // remove oldest command from pile
  shiftRedo() {
    const oldest = this._redostack.shift();
    if (oldest) this._delete(oldest);
  }

  dispose() {}

  // delete what needs to be deleted when a command is removed from pile
  protected _delete(params: T) {}
}
