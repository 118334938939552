<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import { syncer } from "@/repositories/Syncer";
import { useToast } from "@/components/composable/useToast";
import {
  type FileStoreDrawingFile,
  useGetArchivedDrawings,
} from "@/stores/fileStore";
import { formatDistanceToNow } from "@/utils/misc.utils";
import { Logger } from "@/logger";
import SkeletonList from "@/components/UI/skeleton/SkeletonList.vue";

type ArchivedDrawing = FileStoreDrawingFile & {
  archivedAtStr?: string;
};

const drawings = ref<ArchivedDrawing[]>([]);
const { showPrimary } = useToast();
const { isLoading, getArchivedDrawings } = useGetArchivedDrawings();
const query = ref<string>("");

function formatLastEditedAt(drawings: ArchivedDrawing[]) {
  drawings.forEach((drawing) => {
    if (drawing.archivedAt) {
      drawing.archivedAtStr = formatDistanceToNow(drawing.archivedAt);
    }
  });
}

async function unArchive(drawingId: number) {
  Logger.info(`ArchiveListView : unarchive ${drawingId}`);
  await syncer.unArchiveDrawing(drawingId);
  showPrimary("Le plan a été rétabli");
  drawings.value = await getArchivedDrawings();
  filter();
  formatLastEditedAt(drawings.value);
}

const filteredDrawings = ref<ArchivedDrawing[]>([]);

function filter() {
  filteredDrawings.value = drawings.value.filter((drawing) => {
    return query.value ? drawing.name.includes(query.value) : true;
  });
}

onMounted(async () => {
  drawings.value = await getArchivedDrawings();
  formatLastEditedAt(drawings.value);
  filter();
});

onUnmounted(() => {});
</script>

<template>
  <div v-if="isLoading" class="p-5 mx-5">
    <SkeletonList :count="5" height="3rem" />
  </div>
  <div v-else class="p-5 mx-5">
    <div class="d-flex justify-content-end text-end my-2 pb-2">
      <div class="input-group dr-input">
        <input
          class="form-control"
          type="text"
          @input="filter"
          v-model="query"
          placeholder="Rechercher un dessin"
        />
      </div>
    </div>

    <div v-if="filteredDrawings && filteredDrawings.length > 0">
      <ul id="files-list" class="list-group w-100">
        <li
          :key="drawing.id"
          v-for="drawing in filteredDrawings"
          class="list-group-item list-group-item-action d-inline-flex justify-content-between align-items-center bg-white"
        >
          {{ drawing.name }}
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <small
              class="text-muted align-self-center"
              v-if="drawing.archivedAtStr"
            >
              archivé {{ drawing.archivedAtStr }}
            </small>
            <button
              class="btn btn-sm btn-outline-primary"
              @click.prevent="unArchive(drawing.id)"
            >
              Rétablir
            </button>
          </div>
        </li>
      </ul>
    </div>
    <div v-else>
      <p class="fs-4 text-center text-lg-center">Aucun plan archivé</p>
    </div>
  </div>
</template>

<style scoped>
.dr-input {
  max-width: 300px;
}
</style>
