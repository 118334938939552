<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import type { ExposedHtmlInput, TextTarget } from "./text.type";

const props = withDefaults(
  defineProps<{
    target: TextTarget;
    preventFocusOnClear?: boolean;
    focusOnMount?: boolean;
  }>(),
  {
    preventFocusOnClear: false,
    focusOnMount: false,
  }
);

const emit = defineEmits<{
  (e: "update:focusOnMount", value: boolean): void;
}>();

const TextHTMLElem = ref<HTMLInputElement | null>(null);
const preventBlur = ref<boolean>(false);

function clear() {
  props.target.update("");
  // need to wait for element to update
  // delay(() => autoGrow(), 100);
  // focus element on clear to save a click
  if (!props.preventFocusOnClear) {
    TextHTMLElem.value?.focus();
  }
}

function handleInput(): void {
  if (TextHTMLElem.value?.value) {
    props.target.update(TextHTMLElem.value.value);
    //autoGrow();
  }
}

function disableBlur() {
  preventBlur.value = true;
}

function focus() {
  TextHTMLElem.value?.focus();
}

function blur() {
  TextHTMLElem.value?.blur();
}

/*function autoGrow() {
  if (!TextHTMLElem.value) return;
  if (TextHTMLElem.value.scrollHeight > TextHTMLElem.value.clientHeight) {
    TextHTMLElem.value.style.height = `${TextHTMLElem.value.scrollHeight}px`;
  } else {
    TextHTMLElem.value.style.height = "auto";
  }
}*/

watch(
  () => props.focusOnMount,
  (value) => {
    if (value) {
      TextHTMLElem.value?.focus();
      emit("update:focusOnMount", false);
    }
  },
  {
    immediate: true,
  }
);

function handleBlur(e: FocusEvent) {
  if (preventBlur.value) {
    console.log("Prevented blur");
    // Prevent the blur event by refocusing the input
    e.preventDefault();
    e.stopImmediatePropagation();
    TextHTMLElem.value?.focus();
    preventBlur.value = false; // Reset the flag
  }
}

defineExpose<ExposedHtmlInput>({
  focus,
  blur,
});
</script>
<template>
  <input
    ref="TextHTMLElem"
    :value="props.target.message"
    key="TextInput"
    rows="1"
    @blur="handleBlur"
    @input="handleInput"
    class="form-control overflow-hidden"
    placeholder="Texte"
  />
  <button
    class="btn btn-secondary"
    @pointerdown="disableBlur"
    @click.prevent.stop="clear"
  >
    <i class="bi bi-x-lg" />
  </button>
</template>

<style></style>
