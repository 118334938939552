<script setup lang="ts">
import CanvasSettingsState from "@/stores/CanvasSettingsState";
import SizeMenu from "./SizeMenu.vue";
import type { NoteConfig } from "@/stores/UserState";
import { Logger } from "@/logger";
import NoteMenuState from "@/stores/NoteMenuState";
import ScaleMenuState from "@/stores/ScaleMenuState";
import ScaleItem from "./ScaleItem.vue";

function activateScale(scale: NoteConfig) {
  Logger.info(`ScaleMenu.vue : Activating scale ${JSON.stringify(scale)}`);
  ScaleMenuState.setActiveScale(scale);
  const computed = NoteMenuState.updateComputedNoteConfig();
  CanvasSettingsState.updateAndPersist();
  Logger.info(
    `ScaleMenu.vue : current computed config is ${JSON.stringify(computed)}`
  );
}

function updateScales(): void {
  Logger.info(`ScaleMenu.vue : start updating scales`);
  ScaleMenuState.updateScales();
  Logger.info(`ScaleMenu.vue : done updating scales`);
}
</script>

<template>
  <div class="btn-group dropup">
    <button
      type="button"
      class="btn btn-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click="updateScales()"
    >
      {{
        ScaleMenuState.activeScale ? ScaleMenuState.activeScale.name : "Echelle"
      }}
    </button>
    <ul
      class="dropdown-menu dropdown-menu-dark"
      style="max-height: 60vh; overflow-y: auto"
    >
      <li>
        <h6 class="dropdown-header">Vos styles à insérer</h6>
      </li>
      <li
        v-for="scale in ScaleMenuState.scales[1]"
        :key="scale.name"
        @click="activateScale(scale)"
      >
        <ScaleItem :scale="scale" :active-scale="ScaleMenuState.activeScale" />
      </li>
      <li>
        <hr class="dropdown-divider" />
      </li>
      <li>
        <h6 class="dropdown-header">Dans le dessin</h6>
      </li>
      <li
        v-for="scale in ScaleMenuState.scales[0]"
        :key="scale.name"
        @click="activateScale(scale)"
      >
        <ScaleItem :scale="scale" :active-scale="ScaleMenuState.activeScale" />
      </li>
      <SizeMenu />
    </ul>
  </div>
</template>
