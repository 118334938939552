<script setup lang="ts">
import { useScreen } from "@/components/composable/useResponsive";
import type { MenuLinkItem } from "./types";

defineProps<{
  item: MenuLinkItem;
}>();

const screen = useScreen();
</script>

<template>
  <el-menu-item
    :index="item.index"
    :route="item.route"
    class="rounded"
    :style="{
      height: screen.lt.lg ? '4.5rem' : '3rem',
    }"
  >
    <div
      :class="
        screen.lt.lg
          ? 'w-100 d-flex flex-column gap-1 justify-content-center align-items-center'
          : 'd-flex gap-2 align-items-center'
      "
    >
      <el-icon style="margin: 0"><i :class="item.icon" /></el-icon>
      <span>{{ item.label }}</span>
    </div>
  </el-menu-item>
</template>

<style lang="scss" scoped>
.el-menu-item {
  line-height: 1;
}

.el-menu-item.is-active {
  background-color: var(--bs-light);
  color: var(--bs-primary);
}
</style>
