import { TEMP_LAYER } from "./DeleteEntities";
import Command from "./Command";
import type { Layer } from "../builders/LayerBuilder";
import { EntityBuilder } from "../builders/EntityBuilder";

type EntityParams = {
  handle: string;
  layername: Layer["name"];
}[];

export default class AddEntities extends Command<EntityParams> {
  // Takes entity in temp layer and re-add it by setting back the layer
  _reexecute(params: EntityParams) {
    for (const param of params) {
      EntityBuilder.setLayerByHandle(param.handle, param.layername);
    }

    this._viewer.update();
  }
  // move entity in Temp layer
  _unexecute(params: EntityParams) {
    const handles = params.map((param) => param.handle);
    EntityBuilder.setLayerByHandles(handles, TEMP_LAYER.name);
    this._viewer.update();
  }

  _delete(params: EntityParams) {
    const handles = params.map((param) => param.handle);
    EntityBuilder.removeByHandlesIfTemp(handles);
  }

  getParams(entIds: VisualizeJS.OdTvEntityId[]): EntityParams {
    const params: EntityParams = [];
    for (const entId of entIds) {
      params.push({
        handle: EntityBuilder.getHandle(entId),
        layername: EntityBuilder.getLayerName(entId),
      });
    }
    return params;
  }
}
