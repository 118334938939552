import { reactive } from "vue";
import type { UserProperties } from "../APIClient";
import type { EntityProps } from "../open-cloud/builders/EntityBuilder";
import { DEFAULT_LEGEND } from "../defaultLegend";
import type { Layer } from "../open-cloud/builders/LayerBuilder";
import { db } from "@/repositories/database";

const LOCAL_STORAGE_USER_KEY = "user";
export const LOCAL_STORAGE_JWT_KEY = "jwt";

export interface NoteConfig {
  name: string;
  label?: string;
  icon?: string;
  type?: "photo" | "default" | "general";
  hatch?: HatchProp;
  categories?: string[];
  cloneSelectionSet?: boolean;
  props: EntityProps;
}

export type HatchProp = {
  name: string; // name of the pattern
  rotation?: number; // in degrees
};

export type Legend = {
  id: number;
  name: string;
  version: number;
  note_configs: NoteConfig[];
  layer_configs: Layer[];
  scale_configs: NoteConfig[];
};

type UserState = {
  data: null | UserProperties;
  isLoggedIn: boolean;
  isInternalUser: () => boolean;
  isSuperAdmin: () => boolean;
  updateAndPersist: (user: UserProperties) => void;
  initialize: () => void;
  reset: () => void;
  getLegends: () => Legend[];
  hasSeenRenderAlert: boolean;
};

export default reactive<UserState>({
  data: null,
  isLoggedIn: false,
  hasSeenRenderAlert: false,

  /**
   * Update the user state and persist to LocalStorage.
   */
  updateAndPersist(user: UserProperties) {
    this.data = user;

    this.isLoggedIn = true;
    localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(this.data));

    // if user has no legends, set a default one
    if (!user.legends || !user.legends.length) {
      this.data.legends = [DEFAULT_LEGEND];
    }
  },

  /**
   *  Initializes the user state from LocalStorage.
   */
  initialize() {
    const storedUser = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
    if (storedUser) {
      const user = JSON.parse(storedUser) as UserProperties;
      if (user.id) {
        this.data = user;
        this.isLoggedIn = true;
      } else {
        // in this case, it means that the stored user doens't have the necessary information
        // we might as well clean it from the storage
        localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
      }
    }
  },

  reset() {
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    localStorage.removeItem(LOCAL_STORAGE_JWT_KEY);
    void db.wipeDatabase();
    this.isLoggedIn = false;
    this.data = null;
  },
  isInternalUser() {
    const rgs = [/@diag(nosticrapid)?.(fr|com)$/];
    return rgs.some((rg) => this.data?.email && rg.test(this.data?.email));
  },
  isSuperAdmin() {
    return this.data?.role === "SUPERADMIN";
  },
  getLegends(): Legend[] {
    return this.data?.legends ?? [];
  },
});
