import { odaRepository } from "@/repositories/oda.repository";
import { Logger } from "@/logger";
import apiClient from "@/APIClient";
import { drawingRepository } from "@/repositories/drawing.repository";
import { propertyRepository } from "./property.repository";
import type {
  UploadedFileDataHook,
  UploadProgressHook,
} from "@/stores/fileStore";
import { map } from "lodash";
import type { DRDrawing } from "@/repositories/domain.types";

type DrawingStat =
  | "SERVER_AHEAD_NO_LOCAL"
  | "SERVER_AHEAD_WITH_LOCAL"
  | "LOCAL_AHEAD"
  | "CONFLICT_LOCAL_LAST"
  | "CONFLICT_SERVER_LAST"
  | "NOT_FOUND_ON_SERVER";

type Stats = Record<number, DrawingStat>;

export class Syncer extends EventTarget {
  maxFileNB = 40;

  /**
   * to understand this function, one must be aware of the distinction between
   * 1. not having the lastest drawing' revision: indexdb drawings[x].lastRevision.id is not hte latest ID
   * 2. having the lastest revision: we have the last revision in indexdb, meaning the ids' are up to date
   * 3. having the latest revision vsf: what happens when we "make local". Having lastRevision.vsf non null and matching lastRevision.id
   */
  async autoSync() {
    // we analyse the difference between the server and the local
    const { stats, drawings } = await this.syncStats();
    // this adds new drawing from server that were not even existing in local
    await drawingRepository.createMany(drawings);

    // looping through the more complex cases, where the drawing is existing but the revisions changed
    return Promise.all(
      map(stats, async (stat, drawingIdStr) => {
        const drawingId = Number.parseInt(drawingIdStr, 10);
        // first we check if it's a dangling local drawing we should remove, this is for archived drawings
        if (stat === "NOT_FOUND_ON_SERVER") {
          await drawingRepository.deleteById(drawingId);
          return;
        }

        // we retrieve the server drawing, should exist, as we checked the archived usecase just above
        const drawing = drawings.find((d) => d.id === drawingId);
        if (!drawing) {
          throw new Error("cannot find drawing from stats");
        }
        if (
          stat === "SERVER_AHEAD_NO_LOCAL" ||
          stat === "SERVER_AHEAD_WITH_LOCAL"
        ) {
          // in those both case, we want to have the last revision information
          await drawingRepository.replace(drawing);
          // we download the new vsf only if we previously had the vsf downloaded
          if (stat === "SERVER_AHEAD_WITH_LOCAL") {
            return this.makeLastRevisionLocal(drawingId);
          } else {
            return null;
          }
        } else if (stat === "LOCAL_AHEAD") {
          return this.saveRevision(drawingId);
        } else if (
          stat === "CONFLICT_LOCAL_LAST" ||
          stat === "CONFLICT_SERVER_LAST"
        ) {
          // we treat both case the same way, first revision save to server keeps the name and the other one is duplicated
          return this.saveDraftAsDuplicate(drawing);
        }
      })
    );
  }

  /**
   * This will fetch drawings and analyse status of each local drawing with the server. Doesn't write anything, just build information
   */
  async syncStats(): Promise<{ stats: Stats; drawings: DRDrawing[] }> {
    const stats: Stats = {};
    const serverDrawings = await apiClient.fetchDrawings();
    const localDrawings = await drawingRepository.fetchAllByNewest();
    serverDrawings.forEach((serverDrawing) => {
      const local = localDrawings.find(
        (localDrawing) => localDrawing.id === serverDrawing.id
      );
      if (!local) {
        return;
      }

      const { lastRevision: localRevision } = local;
      const revisionAreEqual =
        localRevision.id === serverDrawing.lastRevision.id;

      if (!localRevision.vsf) {
        // we never made the drawing local
        if (!revisionAreEqual) {
          stats[local.id] = "SERVER_AHEAD_NO_LOCAL";
        }
      } else {
        // we made the drawing local at least once
        if (!localRevision.draft) {
          // we don't have  draft (unsaved modification)
          if (!revisionAreEqual) {
            stats[local.id] = "SERVER_AHEAD_WITH_LOCAL";
          }
        } else {
          // we have a draft (unsaved modification)
          if (revisionAreEqual) {
            // we are ahead of the server: we have the last version + a draft
            stats[local.id] = "LOCAL_AHEAD";
          } else {
            // we are behind the server
            if (!localRevision.editedAt) {
              Logger.warn(
                "Syncer.syncStats() : we locally have a revision that has a draft but no editedAt"
              );
              return;
            }
            if (localRevision.editedAt > serverDrawing.lastRevision.savedAt) {
              // we have a more recent draft than the server
              stats[local.id] = "CONFLICT_LOCAL_LAST";
            } else {
              stats[local.id] = "CONFLICT_SERVER_LAST";
            }
          }
        }
      }
    });

    // look for drawings on local that aren't on the server anymore (probably archived)
    localDrawings.forEach((localDrawings) => {
      const server = serverDrawings.find(
        (localDrawing) => localDrawing.id === localDrawings.id
      );
      if (!server) {
        stats[localDrawings.id] = "NOT_FOUND_ON_SERVER";
      }
    });

    return { stats: stats, drawings: serverDrawings };
  }

  async saveDraftAsDuplicate(serverDrawing: DRDrawing) {
    const drawing = await drawingRepository.getByIdOrThrow(serverDrawing.id);
    if (!drawing.lastRevision.draft) {
      throw new Error("cannot save draft as duplicate: no draft");
    }
    const newRevisionFile = await odaRepository.uploadOneVsfFromBuffer(
      drawing.lastRevision.draft,
      odaRepository.safeTimestampToFileName(drawing.name)
    );
    const newDrawing = await apiClient.forkDrawing(
      drawing.id,
      newRevisionFile.id
    );

    await drawingRepository.createSafeById(newDrawing);
    await this.makeLastRevisionLocal(newDrawing.id);

    await drawingRepository.replace(serverDrawing);
    await this.makeLastRevisionLocal(serverDrawing.id);
  }

  // Fetches the Last revision on OCS server for the drawingId

  async makeLastRevisionLocal(drawingId: number) {
    const drawing = await drawingRepository.getByIdOrThrow(drawingId);
    const vsf = await odaRepository.downloadVSFFile(
      drawing.lastRevision.openCloudFileId
    );

    return drawingRepository.saveLastRevisionVsf(drawing.id, vsf);
  }

  async makeInitialDrawingPropertiesLocal(
    initialOpenCloudFileId: string,
    userId: number
  ) {
    if (await propertyRepository.exists(initialOpenCloudFileId)) return;
    const properties = await odaRepository.fetchProperties(
      initialOpenCloudFileId,
      userId
    );
    return propertyRepository.createSafeById(properties);
  }

  uploadManyDwg(
    domFiles: FileList | File[],
    onProgressCallbacks: Array<{
      onProgress: UploadProgressHook;
      onFileUploaded: UploadedFileDataHook;
    }>
  ) {
    Array.from(domFiles).map((domFile, i) =>
      this.uploadOneDwg(domFile, onProgressCallbacks[i])
    );
  }

  async uploadOneDwg(
    domFile: File,
    hooks: {
      onProgress: UploadProgressHook;
      onFileUploaded: UploadedFileDataHook;
    }
  ) {
    await odaRepository
      .uploadOneDwg(domFile, hooks.onProgress)
      .then((fileData) => {
        hooks.onFileUploaded(fileData);
      });
    this.autoSync(); //TODO: is this necessary?
  }

  async getOdaFile(fileData: any) {
    return await odaRepository.getOdaFile(fileData);
  }

  async saveRevision(drawingId: number): Promise<void> {
    const drawing = await drawingRepository.getByIdOrThrow(drawingId);
    const draft = drawing.lastRevision.draft;
    if (!draft) {
      Logger.error(`Cannot save revision as draft is empty`);
      return;
    }
    const newRevisionFile = await odaRepository.uploadOneVsfFromBuffer(
      draft,
      odaRepository.safeTimestampToFileName(drawing.name)
    );
    const updatedDrawing = await apiClient.createRevision(
      drawingId,
      newRevisionFile.id,
      "VSFX",
      drawing.lastRevision.id
    );
    updatedDrawing.lastRevision.vsf = draft;
    const count = await drawingRepository.update(drawingId, {
      lastRevision: updatedDrawing.lastRevision,
    });
    if (count !== 1) {
      throw new Error(
        `Revision for drawing ${drawingId} was not saved. Update operation result was ${count}`
      );
    }
  }

  dropDraft(drawingId: number): Promise<number> {
    return drawingRepository.dropDraft(drawingId);
  }

  async archiveDrawingAndRefreshList(drawingId: number): Promise<void> {
    // we ask the server to archive, and then trigger a standard sync that will notice the recent archiving and remove the drawing from the list
    await apiClient.archiveDrawing(drawingId);
    await this.autoSync();
  }

  async fetchArchivedDrawings(): Promise<DRDrawing[]> {
    return apiClient.fetchDrawings(true);
  }

  async unArchiveDrawing(drawingId: number): Promise<void> {
    await apiClient.unArchiveDrawing(drawingId);
  }

  async getConvertedToDwg(drawingId: number): Promise<ArrayBuffer> {
    const start = performance.now();
    const { fileId, resourceId } = await apiClient.startConversionToDwg(
      drawingId
    );
    const end = performance.now();
    Logger.info(`syncer.getConvertedToDwg : conversion took ${end - start} ms`);
    return odaRepository.downloadDWGFile(fileId, resourceId);
  }
}

export const syncer = new Syncer();
