import { Logger } from "@/logger";
import LibSingleton from "../LibSingleton";
import { InsertBuilder } from "./InsertBuilder";

export type Model = {
  pseudo: "ACTIVE" | "MUP";
};

export class ModelBuilder {
  // Allows to clone entities to a Model
  // There is no way to move an entity to another model. Another entity must be created
  // in the targeted model and the property of the first entity copied to the new one.
  // There are several types of entities. They have different
  // properties and are then treated separately.

  // ulysse: what about  https://cloud.opendesign.com/docs/index.html#/vis/OdTvEntity?id=copytotargetentityid ?

  static cloneEntity(
    entId: VisualizeJS.OdTvEntityId,
    model: VisualizeJS.TvModel
  ): VisualizeJS.OdTvEntityId {
    if (entId.getType() === 1) {
      const entPtr = entId.openObject();
      const newName = entPtr.getName();
      const entIdTemp = model.appendEntity(newName);
      entPtr.copyTo(entIdTemp);
      entPtr.delete();
      return entIdTemp;
    } else if (entId.getType() === 2) {
      const insertPtr = entId.openObjectAsInsert();
      const entIdTemp = model.appendInsert(
        insertPtr.getBlock(),
        insertPtr.getName()
      );
      InsertBuilder.copyToInsert(entId, entIdTemp);
      insertPtr.delete();
      return entIdTemp;
    } else {
      Logger.error(`ModelBuilder.cloneEntity : entity type is unknown`);
      throw new TypeError("TypeError : entity type is not 1 or 2");
    }
  }

  static cloneEntities(
    entIds: VisualizeJS.OdTvEntityId[],
    model: VisualizeJS.TvModel
  ): VisualizeJS.OdTvEntityId[] {
    const cloneIds: VisualizeJS.OdTvEntityId[] = [];
    for (const entId of entIds) {
      const cloneId = ModelBuilder.cloneEntity(entId, model);
      cloneId ? cloneIds.push(cloneId) : null;
    }
    return cloneIds;
  }

  static findModel(modelRef: Model["pseudo"]): VisualizeJS.TvModel {
    if (modelRef === "ACTIVE") return LibSingleton.viewer.getActiveModel();
    else return LibSingleton.viewer.getMarkupModel();
  }

  static getEntityByHandle(
    handle: string,
    pseudo: Model["pseudo"] = "ACTIVE"
  ): VisualizeJS.OdTvEntityId | null {
    const model = ModelBuilder.findModel(pseudo);
    const entId = model.findEntity(handle);
    if (!entId || entId?.isNull()) {
      entId.delete();
      return null;
    }
    return entId;
  }

  static getModel(pseudo: Model["pseudo"]): VisualizeJS.TvModel {
    const viewer = LibSingleton.viewer;
    let model: VisualizeJS.TvModel;
    switch (pseudo) {
      case "ACTIVE":
        model = viewer.getActiveModel();
        break;
      case "MUP":
        model = viewer.getMarkupModel();
        break;
      default:
        throw new Error(`can't find model for pseudo ${pseudo}`);
    }
    return model;
  }
  // create an entity with the name argument and in the model (pseudo of the model)
  static createEntity(
    name: string,
    pseudo: Model["pseudo"]
  ): VisualizeJS.OdTvEntityId {
    const model = ModelBuilder.getModel(pseudo);
    const entId = model.appendEntity(name);
    model.delete();
    return entId;
  }

  static createInsert(
    name: string,
    blockId: VisualizeJS.OdTvBlockId,
    pseudo: Model["pseudo"]
  ): VisualizeJS.OdTvEntityId {
    const model = ModelBuilder.getModel(pseudo);
    const insertId = model.appendInsert(blockId, name);
    return insertId;
  }

  static removeEntity(
    entId: VisualizeJS.OdTvEntityId,
    pseudo: Model["pseudo"]
  ) {
    const model = ModelBuilder.getModel(pseudo);
    model.removeEntity(entId);
    model.delete();
  }

  static logModels() {
    const itr = LibSingleton.viewer.getModelIterator();
    Logger.debug("Log models' names");
    for (; !itr.done(); itr.step()) {
      const model: VisualizeJS.TvModel = itr.getModel();
      Logger.debug(model.getName());
      model.delete();
    }
    itr.delete();
  }
}
