<script setup lang="ts">
import AddFile from "@/components/files/AddFile.vue";
import FilesOptions from "./FilesOptions.vue";
import { useScreen } from "../composable/useResponsive";

const screen = useScreen();
</script>

<template>
  <div class="d-flex gap-2 justify-content-end text-end my-2 pb-2">
    <FilesOptions />

    <div class="flex-grow-1"></div>
    <AddFile :mini="screen.lt.sm" />
  </div>
</template>
