<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";

const props = withDefaults(
  defineProps<{
    description?: string;
    extensions?: string[];
  }>(),
  {
    extensions: () => [],
  }
);

const emit = defineEmits<{
  (e: "files", files: File[]): void;
}>();
const isDragging = ref(false);
const dragCounter = ref(0);

function handleDragEnter(event: DragEvent) {
  event.preventDefault();
  event.stopPropagation();
  dragCounter.value++;
  if (dragCounter.value === 1) {
    isDragging.value = true;
  }
}

function handleDragLeave(event: DragEvent) {
  event.preventDefault();
  event.stopPropagation();
  dragCounter.value--;
  if (dragCounter.value === 0) {
    isDragging.value = false;
  }
}

function handleDragOver(event: DragEvent) {
  event.preventDefault();
  event.stopPropagation();
}

function handleDrop(event: DragEvent) {
  event.preventDefault();
  event.stopPropagation();
  dragCounter.value = 0;
  isDragging.value = false;
  if (event.dataTransfer) {
    const files = event.dataTransfer.files;
    handleFiles(files);
  }
}

function handleFiles(files: FileList) {
  const validatedFiles = Array.from(files).filter((file) => {
    const extension = file.name.split(".").pop()?.toLowerCase();
    return props.extensions.includes(extension || "");
  });

  emit("files", validatedFiles);
}

onMounted(() => {
  window.addEventListener("dragover", handleDragOver);
  window.addEventListener("dragenter", handleDragEnter);
  window.addEventListener("dragleave", handleDragLeave);
  window.addEventListener("drop", handleDrop);
});

onUnmounted(() => {
  window.removeEventListener("dragover", handleDragOver);
  window.removeEventListener("dragenter", handleDragEnter);
  window.removeEventListener("dragleave", handleDragLeave);
  window.removeEventListener("drop", handleDrop);
});
</script>

<template>
  <div
    id="drop-zone"
    @dragover.prevent="handleDragOver"
    @dragenter.prevent="handleDragEnter"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
    :class="{ dragging: isDragging }"
    :style="{ pointerEvents: isDragging ? 'auto' : 'none' }"
  >
    <div
      v-if="isDragging"
      class="d-flex align-items-center justify-content-center h-100 w-100"
    >
      <div class="text-center">
        <p class="h4">Déposer vos fichiers</p>
        <p v-if="description" class="text-black-50">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<style>
#drop-zone {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000;
}

#drop-zone.dragging {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
