import type { RouteLocationRaw } from "vue-router";

enum MenuType {
  GROUP = "group",
  LINK = "link",
}

interface MenuLinkItem {
  label: string;
  icon: string;
  route: RouteLocationRaw;
  index: string;
}

interface MenuGroupItem {
  label: string;
  children: MenuEntity[];
}

interface MenuEntity {
  type: MenuType;
  group?: MenuGroupItem;
  link?: MenuLinkItem;
}

export { MenuType };
export type { MenuLinkItem, MenuGroupItem, MenuEntity };
