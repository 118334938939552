import { TextBuilder, type TextData } from "@/open-cloud/builders/TextBuilder";
import OneWayEntityDragger from "./OneWayEntityDragger";
import Toolbox from "@/open-cloud/builders/ODAToolbox";

export default class StraightLineDragger extends OneWayEntityDragger {
  autoSelect = false;

  _updateFrame(): void {
    if (this.startCornerWCS.length === 3 && this.endCornerWCS.length === 3) {
      this.refreshShadowEntity();
      if (this.shadowId) {
        this.drawStraightLine(
          this.shadowId,
          this.startCornerWCS,
          this.endCornerWCS,
          this.viewer.activeNoteConfig.label
        );
        this.drawDMeasurements();
        this._setNoteConfigProperties(this.shadowId);
      }
    }
  }

  drawStraightLine(
    entityId: VisualizeJS.OdTvEntityId,
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number],
    label?: string
  ) {
    const entity = entityId.openObject();
    const poly = entity.appendPolyline([
      startCornerWCS[0],
      startCornerWCS[1],
      0,
      endCornerWCS[0],
      endCornerWCS[1],
      0,
    ]);

    const center = Toolbox.computeCenter(startCornerWCS, endCornerWCS);
    if (label && this.viewer.label) {
      const data: TextData = {
        refpoint: center,
        message: label,
        alignmentmode: 10,
      };
      TextBuilder.addText(entityId, data);
    }

    poly.delete();
    entity.delete();
  }
}
