<script setup lang="ts">
import { useScreen } from "@/components/composable/useResponsive";
import DRMenuLinkItem from "./DRMenuLinkItem.vue";
import type { MenuEntity } from "./types";
import { MenuType } from "./types";

defineProps<{
  item: MenuEntity;
}>();

const screen = useScreen();
</script>

<template>
  <DRMenuLinkItem
    v-if="item.type === MenuType.LINK && item.link"
    :item="item.link"
  >
  </DRMenuLinkItem>
  <template v-else-if="item.type === MenuType.GROUP && item.group">
    <el-menu-item-group
      class="mt-3 mb-1"
      :class="screen.lt.lg ? 'text-center' : 'px-4'"
    >
      <span
        class="text-uppercase fw-light text-center text-dark"
        style="font-size: 0.7rem"
        >{{ item.group.label }}</span
      ></el-menu-item-group
    >

    <DRMenuItem
      v-for="(childItem, id) in item.group.children"
      :key="id"
      :item="childItem"
    />
  </template>
</template>

<style lang="scss" scoped></style>
