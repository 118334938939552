import SequentialEntityDragger from "./SequentialEntityDragger";
import { clearCursor, isExtremitiesClose } from "../utils/close.utils";
import {
  appendCursorEntityToModel,
  ORIGIN_DESIGN_PROPS,
  ORIGIN_RADIUS,
} from "../utils/cursors";
import { checkIfCloseAndAddCursorStart } from "../utils/close.utils";
import ShellBuilder from "@/open-cloud/builders/ShellBuilder";
import HatchLibrary from "@/hatches/library";
import type { HatchProp } from "@/stores/UserState";
import OdaGeometryUtils from "@/open-cloud/builders/odaGeometry.utils";
import { ModelBuilder } from "@/open-cloud/builders/ModelBuilder";
import Toolbox from "@/open-cloud/builders/ODAToolbox";

export default class PolylineDragger extends SequentialEntityDragger {
  coordinates: number[] = [];
  cursorId: VisualizeJS.OdTvEntityId | null = null;

  end(x: number, y: number) {
    if (!this.shadowId) return;
    // if extremities are close, swap end corner with start
    let isClose = false;
    if (this.viewer.closeContour) {
      isClose = isExtremitiesClose([...this.coordinates, ...this.endCornerWCS]);
      if (isClose) {
        this.endCornerWCS = this.coordinates.slice(undefined, 3) as [
          number,
          number,
          number
        ];
      }
    }

    super.end(x, y, false);
    clearCursor(this.cursorId, this.viewer);
    if (isClose) {
      if (
        this.viewer.activeNoteConfig.hatch != undefined &&
        this.entity &&
        !this.entity.isNull()
      ) {
        this.drawHatches(
          this.entity,
          this.coordinates,
          this.viewer.activeNoteConfig.hatch
        );
      }
      this._setNoteConfigProperties(this.entity);
      this.clear();
    } else {
      this._setNoteConfigProperties(this.entity);
    }
  }

  drawShadow() {
    if (this.endCornerWCS.length) {
      const model = ModelBuilder.findModel("MUP");
      const radius = Toolbox.screenDistanceToWorld(ORIGIN_RADIUS);
      this.shadowId = appendCursorEntityToModel(
        model,
        this.endCornerWCS,
        radius
      );
      this._setProperties(this.shadowId, ORIGIN_DESIGN_PROPS);
      if (!this.viewer.closeContour) return;
      this.cursorId = checkIfCloseAndAddCursorStart(
        this.cursorId,
        [...this.coordinates, ...this.endCornerWCS],
        this.viewer,
        12
      ); // 4 points mini ie a triangle
      model.delete();
    }
  }

  createNew(): VisualizeJS.OdTvEntityId {
    const model: VisualizeJS.TvModel = ModelBuilder.findModel("ACTIVE");
    const newEntId = model.appendEntity("DR_POLYLINE");
    const newEntPtr = newEntId.openObject();
    this.coordinates.push(...this.endCornerWCS);
    const poly = newEntPtr.appendPolyline(this.coordinates);
    poly.delete();
    newEntPtr.delete();
    return newEntId;
  }

  drawHatches(
    entId: VisualizeJS.OdTvEntityId,
    points: number[],
    hatch: HatchProp
  ) {
    // get user Turn Direction to calculate face normal
    // if turn direction is clockwise, faces normal will point backward
    // hatch will then be symetric relative to Y-axis
    const turnDirection = OdaGeometryUtils.getTurnDirection(this.coordinates);

    const flipY = turnDirection === "Clockwise" ? true : false;
    const item = HatchLibrary.getByName(hatch.name, hatch.rotation, flipY);

    if (!item) return;

    const geomId = ShellBuilder.appendPolylineShell(entId, points);

    ShellBuilder.setHatchPattern(geomId, item.hatches);

    geomId.delete();
  }

  clear() {
    super.clear();
    this.coordinates = [];
  }
}
