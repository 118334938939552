import { Logger } from "@/logger";
import BaseEntityDragger from "./BaseEntityDragger";
import { ModelBuilder } from "@/open-cloud/builders/ModelBuilder";
import { EntityBuilder } from "@/open-cloud/builders/EntityBuilder";
export default class OneWayEntityDragger extends BaseEntityDragger {
  autoSelect = true;
  end(/*x: number, y: number*/) {
    if (
      this.shadowId &&
      !this.shadowId.isNull() &&
      this.viewer.activeNoteConfig.props.model
    ) {
      const model: VisualizeJS.TvModel = ModelBuilder.findModel("ACTIVE");
      const entId = ModelBuilder.cloneEntity(this.shadowId, model);
      if (entId) {
        this.autoSelect
          ? this.viewer.selectionSetBuilder.selectEntityManually(entId)
          : null;

        const handle = EntityBuilder.getHandle(entId);
        const layername = EntityBuilder.getLayerName(entId);
        this.viewer.commandFactory.addEntities.execute([
          {
            handle: handle,
            layername: layername,
          },
        ]);
        entId.delete();
      }
      model.delete();
    }

    Logger.info(
      `OneWayEntityDragger.end : ${
        this.viewer.activeDragger()?.name
      } added an entity`
    );

    this.clear();
  }
}
