<script setup lang="ts">
import UserState from "@/stores/UserState";
import OfflineIndicator from "@/components/files/OfflineIndicator.vue";

import OffcanvasHelp from "@/components/layouts/navbar/OffcanvasHelp.vue";
import OffcanvasAccountInfo from "@/components/layouts/navbar/OffcanvasAccountInfo.vue";
import SettingsToggler from "@/components/layouts/navbar/SettingsToggler.vue";
import DRLogo from "@/components/UI/DRLogo.vue";
import DRMenu from "@/components/UI/menu/DRMenu.vue";
import { computed } from "vue";
import type { MenuEntity } from "@/components/UI/menu/types";
import { MenuType } from "@/components/UI/menu/types";
import AddFile from "@/components/files/AddFile.vue";
import UploadToast from "@/components/files/UploadList/UploadToast.vue";

import DrawingUploader from "@/components/files/DrawingUploader.vue";
import { useScreen } from "@/components/composable/useResponsive";

const menu = computed<MenuEntity[]>(() => {
  const res: MenuEntity[] = [
    {
      type: MenuType.GROUP,
      group: {
        label: "Projets",
        children: [
          {
            type: MenuType.LINK,
            link: {
              icon: "bi bi-collection",
              label: "En cours",
              route: { name: "home" },
              index: "home",
            },
          },
          {
            type: MenuType.LINK,
            link: {
              icon: "bi bi-archive",
              label: "Archives",
              route: { name: "archives" },
              index: "archives",
            },
          },
        ],
      },
    },
  ];

  if (UserState.isSuperAdmin()) {
    res.push({
      type: MenuType.GROUP,
      group: {
        label: "Organisation",
        children: [
          {
            type: MenuType.LINK,
            link: {
              icon: "bi bi-people",
              label: "Membres",
              route: { name: "admin" },
              index: "admin",
            },
          },
        ],
      },
    });
  }

  return res;
});

const screen = useScreen();
</script>

<template>
  <el-container class="h-100">
    <el-aside
      :width="screen.lt.lg ? '8rem' : '15rem'"
      class="bg-white border-end border-gray h-100"
    >
      <div class="d-flex flex-column h-100">
        <div class="px-4 py-3">
          <DRLogo />
        </div>

        <el-scrollbar class="flex-grow-1">
          <DRMenu :menu="menu" />
        </el-scrollbar>

        <div class="px-4 py-3 flex-column">
          <div class="mb-3 d-flex justify-content-center align-items-center">
            <OfflineIndicator />
          </div>
          <AddFile class="w-100" :mini="screen.lt.lg" />
        </div>
      </div>
    </el-aside>

    <el-container>
      <el-header
        class="bg-white border-bottom border-gray d-flex align-items-center"
      >
        <div class="h4 flex-grow-1">
          {{ $route.meta.title }}
        </div>

        <SettingsToggler />
        <OffcanvasHelp />
        <div class="ps-2"><OffcanvasAccountInfo /></div>
      </el-header>

      <el-main style="padding: 0">
        <el-scrollbar>
          <RouterView></RouterView>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
  <UploadToast />
  <DrawingUploader />
</template>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  background: var(--bs-light);
}
</style>
