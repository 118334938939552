<script setup lang="ts">
import BlockLibrary from "@/blocks/library";
import { Logger } from "@/logger";
import DraggerOptionState from "@/stores/DraggerOptionState";
import { computed } from "vue";

const names = BlockLibrary.getAllNames();

function selectByName(name: string) {
  Logger.info(`BlockLibraryList.ts : select block ${name}`);
  DraggerOptionState.setActiveBlock(name);
}

const activeName = computed(() => DraggerOptionState.activeBlock?.name);
</script>
<template>
  <div
    class="d-flex flex-wrap bg-secondary overflow-hidden rounded block-lib-container"
  >
    <div
      @click="selectByName(name)"
      v-for="(name, index) in names"
      :key="'dr-block-' + index"
      class="p-1 bg-secondary d-flex align-items-center justify-content-center block-item"
      :class="{ 'bg-dark': name === activeName }"
    >
      <img class="block-item-img" :src="'/icons/blocks/' + name + '.svg'" />
    </div>
  </div>
</template>
<style scoped>
.block-item {
  width: 50px;
  height: 50px;
}
.block-item-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.block-lib-container {
  width: 150px;
}
</style>
