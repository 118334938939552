<script setup lang="ts">
import { useDrawingStore } from "@/stores/fileStore";
import { useDeviceState } from "@/stores/DeviceState";
import { Logger } from "@/logger";
import DRBtn from "../UI/DRBtn.vue";
import { ref } from "vue";

withDefaults(
  defineProps<{
    mini?: boolean;
  }>(),
  {
    mini: false,
  }
);

const fileInput = ref<HTMLInputElement | null>(null);

const deviceState = useDeviceState();
// FIXME: not the best, but as `AddFile` can be multiple times in the DOM,
// we need a unique ID for the input below. There's no easy way to achieve
// this with Vue, surprisingly.
const uuid = Math.random().toString();
const drawingStore = useDrawingStore();

async function uploadFile(event: Event) {
  const files = (event.target as HTMLInputElement).files;
  if (files) {
    Logger.info(`AddFile.vue : uploading files, ${files.length}`);
    try {
      drawingStore.uploadFiles(Array.from(files));
    } catch (e) {
      Logger.error(`AddFile.vue : could not upload file, ${JSON.stringify(e)}`);
      throw e;
    }
  } else {
    Logger.info(`AddFile.vue : No file upaloaded`);
  }
}

function openFileDialog() {
  fileInput.value?.click();
}
</script>

<template>
  <input
    ref="fileInput"
    type="file"
    class="d-none file-input-dwg"
    :id="uuid"
    multiple
    accept=".dwg,.vsf,.vsfx"
    @change.prevent="uploadFile"
  />

  <DRBtn
    :class="$attrs.class"
    :label="mini ? '' : 'Ajouter un fichier'"
    icon="bi bi-plus-lg"
    :disabled="!deviceState.isOnline"
    @click="openFileDialog"
  />
</template>
