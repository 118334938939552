<script setup lang="ts">
import { Logger } from "@/logger";
import { TUTORIALS_URL } from "@/utils/constants";

function openHelp() {
  Logger.info(`OffcanvasHelp.vue : open help`);
}
</script>
<template>
  <a
    class="p-3"
    data-bs-toggle="offcanvas"
    href="#offcanvasHelp"
    @click="openHelp()"
    role="button"
    aria-controls="offcanvasHelp"
  >
    <el-icon size="18" class="text-dark">
      <i class="bi bi-question-circle"></i>
    </el-icon>
  </a>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasHelp"
    aria-labelledby="offcanvasHelpLabel"
  >
    <div class="offcanvas-header bg-white">
      <h4 class="offcanvas-title" id="offcanvasHelpLabel">Les aides</h4>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body bg-white">
      <div>
        <h5><strong>Les aides contextuelles</strong></h5>
        <p>
          En restant appuyé quelques secondes sur un bouton ou un menu, vous
          pouvez accéder à l'aide contextuelle de ce bouton.
        </p>
        <h5><strong>La section tutoriel</strong></h5>
        <p>
          <a :href="TUTORIALS_URL" target="_blank" rel="noopener noreferrer"
            >Tutoriels en ligne
          </a>
          <br />
          Mot de passe : OUVRAGEGC
        </p>
      </div>
    </div>
  </div>
</template>
