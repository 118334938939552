import { EntityBuilder } from "../builders/EntityBuilder";
import { ModelBuilder } from "../builders/ModelBuilder";
import OdaGeometryUtils from "../builders/odaGeometry.utils";
import Command from "./Command";
type TransformParams = {
  handle: string;
  vectors: {
    origin: VisualizeJS.Point3;
    x: VisualizeJS.Point3;
    y: VisualizeJS.Point3;
    z: VisualizeJS.Point3;
  };
}[];

export default class TransformEntities extends Command<TransformParams> {
  _unexecute(params: TransformParams) {
    this._applyTransform(params, true);
    this._viewer.update();
  }
  _reexecute(params: TransformParams) {
    this._applyTransform(params, false);
    this._viewer.update();
  }
  _delete(params: TransformParams): void {
    const handles = params.map((param) => param.handle);
    EntityBuilder.removeByHandlesIfTemp(handles);
  }
  _applyTransform(params: TransformParams, invert: boolean) {
    for (const param of params) {
      const entId = ModelBuilder.getEntityByHandle(param.handle);
      if (entId) {
        const { origin, x, y, z } = param.vectors;
        const matrix = OdaGeometryUtils.getMatrixFromVectors(origin, x, y, z);
        if (invert) matrix.invert();
        EntityBuilder.appendTransform(entId, matrix);
        entId.delete();
        matrix.delete();
      }
    }
  }
}
