<script setup lang="ts">
import { onBeforeUnmount, watch } from "vue";
import { RouterView } from "vue-router";
import LegendConfiguratorModal from "./components/providers/LegendConfiguratorModal.vue";
import ToastProvider from "./components/providers/ToastProvider.vue";
import UserState from "@/stores/UserState";
import "./utils/side-effect-imports";
import { odaRepository } from "@/repositories/oda.repository";
import Settings from "./stores/Settings";
import { Logger } from "./logger";
import { DndProvider } from "vue3-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  TouchBackend,
  type TouchBackendOptions,
} from "react-dnd-touch-backend";
import { useDeviceState } from "./stores/DeviceState";
import { useResponsiveController } from "./components/composable/useResponsive";
import PWAToast from "./components/pwa/PWAPopup.vue";

useResponsiveController();

// setup drag & drop backend
const device = useDeviceState();
const backend = device.isTouchDevice() ? TouchBackend : HTML5Backend;
const hasNative =
  document != undefined &&
  (document.elementsFromPoint != undefined ||
    document.msElementsFromPoint != undefined);

function getDropTargetElementsAtPoint(
  x: number,
  y: number,
  dropTargets: HTMLElement[]
): HTMLElement[] {
  return dropTargets.filter((t) => {
    const rect = t.getBoundingClientRect();
    return (
      x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
    );
  });
}

// use custom function only if elementsFromPoint is not supported
const backendOptions: TouchBackendOptions = {
  getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
};

// Need to import Settings to get access to the routes
Settings.initialize();
// Init user before mounting the app in order to get access to the routes
UserState.initialize();
Logger.identify();

// the following updates the syncer API token
// based on the value of UserState.open_cloud_api_token
const unWatchUserState = watch(
  () => UserState.isLoggedIn,
  (/*token*/) => {
    const jwt = localStorage.getItem("jwt")!;
    Logger.info(`Will init oda client with jwt`);
    odaRepository.init(jwt);
  },
  { immediate: true }
);

onBeforeUnmount(() => {
  unWatchUserState();
});
</script>

<template>
  <DndProvider :backend="backend" :options="backendOptions">
    <LegendConfiguratorModal>
      <ToastProvider>
        <RouterView v-slot="{ Component }">
          <template v-if="Component">
            <component :is="Component"></component>
          </template>
        </RouterView>
        <PWAToast />
      </ToastProvider>
    </LegendConfiguratorModal>
  </DndProvider>
</template>

<style lang="scss"></style>
