<script setup lang="ts">
import { useDeviceState } from "@/stores/DeviceState";

const deviceState = useDeviceState();
</script>
<template>
  <span
    v-if="!deviceState.isOnline"
    class="align-items-center d-flex text-muted"
  >
    <i class="bi bi-airplane"></i>
    <span class="ms-2">Offline</span>
  </span>
</template>
