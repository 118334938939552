<script setup lang="ts">
import type {
  GeometryStatus,
  PropertyStatus,
} from "@/repositories/domain.types";
import { computed } from "vue";
import StatusPill from "@/components/files/UploadList/StatusPill.vue";
import type { ColorTheme } from "@/utils/bootstrap.utils";

type FileStatus = "uploading" | GeometryStatus | PropertyStatus;

const props = defineProps<{
  status: FileStatus;
}>();

const colorStatus = computed(() => {
  const colorTable: Record<FileStatus, ColorTheme> = {
    uploading: "warning",
    inprogress: "warning",
    waiting: "warning",
    done: "success",
    failed: "danger",
    none: "danger",
  };
  return colorTable[props.status] || "primary";
});

const humanStatus = computed(() => {
  const translationTable = {
    uploading: "Envoi en cours",
    inprogress: "En cours",
    waiting: "En cours",
    done: "Terminé",
    failed: "Erreur",
    none: "Manquante",
  };
  return translationTable[props.status];
});
</script>

<template>
  <StatusPill :status="colorStatus">{{ humanStatus }} </StatusPill>
</template>
