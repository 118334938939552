import { ViewerEvents, type DRViewer } from "./DRViewer";
import { EntityBuilder, type EntityProps } from "./builders/EntityBuilder";
import { DraggerEvents } from "./draggers/draggers.type";
import { Logger } from "@/logger";
import type { pointArray } from "./types/oda.types";
import { TextBuilder, type TextData } from "./builders/TextBuilder";
import Toolbox from "./builders/ODAToolbox";
import LibSingleton from "./LibSingleton";
import { ModelBuilder } from "./builders/ModelBuilder";

// window.devicePixelRatio allows to adapt to different dpi

const GRADUATION_LENGTH = 12 * window.devicePixelRatio; // px
const SUB_GRADUATION_LENGTH = GRADUATION_LENGTH / 2;
const SUB_GRADUATION_COUNT = 5; // Number of sub graduation per Graduation
const LABEL_HEIGHT = 8 * window.devicePixelRatio; // Font-size in px
const MINIMUM_SPACING = 12 * window.devicePixelRatio; // px, Minimum interval between two closest sub-graduation
const SPACINGS = [1, 5, 10, 20, 50, 100, 500, 1000, 5000, 10000]; // possible graduation spacing in WCS unit, must be ordered from smallest to greatest
const RULER_NAME = "__DR_RULERS"; // Name of the entity
const DEFAULT_HORIZONTAL_RULER_OFFSET = 60 * window.devicePixelRatio; //px

const RULER_DESIGN_PROPS: EntityProps = {
  color: "125,125,125",
  transparency: {
    edge: 0.2,
    face: 0.5,
  },
};
/**
 * This class allows to draw rulers on the edged of the viewer.
 */
export default class Ruler {
  viewer: DRViewer;
  _entityId: VisualizeJS.OdTvEntityId | null = null;
  _origin: pointArray = [0, 0, 0];
  topRightCanvasCornerWCS: pointArray = [0, 0, 0];
  bottomRightCanvasCornerWCS: pointArray = [0, 0, 0];
  bottomRightRulerCornerWCS: pointArray = [0, 0, 0];
  bottomLeftCanvasCornerWCS: pointArray = [0, 0, 0];
  bottomLeftRulerCornerWCS: pointArray = [0, 0, 0];

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
    this.viewer.addEventListener(
      ViewerEvents.OpenDrawing,
      this.initListeners.bind(this)
    );
    // FIXME : we would need a 'pan' event here below
  }

  initListeners() {
    this.viewer.addEventListener(DraggerEvents.AutoPanZoomEnded, () =>
      this.updateRulers()
    );
    // listening to "zoomat" on a touch device slows down simultaneous actions and lags
    this.viewer.addEventListener("zoomat", () => this.updateRulers());
    this.viewer.addEventListener("zoom", () => this.updateRulers());

    this.viewer.addEventListener("resize", () => this.updateRulers());
    this.viewer.addEventListener(DraggerEvents.OriginSet, (e: CustomEvent) => {
      this.origin = e.detail.coordinates;
    });
  }

  get origin() {
    return this._origin;
  }

  set origin(point: pointArray) {
    this._origin = point;
    this.updateRulers();
  }

  get canvas() {
    return this.viewer.canvas;
  }

  updateRulers() {
    this.clearRulers();
    this.drawRulers();
    this.viewer.update();
  }

  clearRulers() {
    if (this._entityId && !this._entityId.isNull()) {
      try {
        const mupModel = ModelBuilder.findModel("MUP");
        mupModel.removeEntity(this._entityId);
        this._entityId.delete();
        mupModel.delete();
        this._entityId = null;
      } catch (e) {
        Logger.error(
          `ruler.clearRulers() : could not clear rulers ${JSON.stringify(e)}`
        );
      }
    }
  }

  updateCornerCoord(canvas: HTMLCanvasElement) {
    const offsetH = Toolbox.screenDistanceToWorld(
      DEFAULT_HORIZONTAL_RULER_OFFSET
    );
    this.topRightCanvasCornerWCS = LibSingleton.viewer.screenToWorld(
      canvas.width,
      0
    );

    this.bottomRightCanvasCornerWCS = LibSingleton.viewer.screenToWorld(
      canvas.width,
      canvas.height
    );

    this.bottomRightRulerCornerWCS = [...this.bottomRightCanvasCornerWCS];
    this.bottomRightRulerCornerWCS[1] += offsetH;
    this.bottomLeftCanvasCornerWCS = LibSingleton.viewer.screenToWorld(
      0,
      canvas.height
    );
    this.bottomLeftRulerCornerWCS = [...this.bottomLeftCanvasCornerWCS];
    this.bottomLeftRulerCornerWCS[1] += offsetH;
  }
  // Return [graduationlength, subgraduationlength]
  computeGradLength(): [number, number] {
    return [
      Toolbox.screenDistanceToWorld(GRADUATION_LENGTH),
      Toolbox.screenDistanceToWorld(SUB_GRADUATION_LENGTH),
    ];
  }

  drawRulers() {
    if (this.canvas) {
      const modelMup = ModelBuilder.findModel("MUP");
      this._entityId = modelMup.appendEntity(RULER_NAME);
      modelMup.delete();
      this.updateCornerCoord(this.canvas);
      const [gradLength, subGradLength] = this.computeGradLength();
      const spacing = this.computeGraduationSpacing();

      this.drawRuler(
        this.bottomRightCanvasCornerWCS,
        this.topRightCanvasCornerWCS,
        this.origin,
        "vertical",
        gradLength,
        subGradLength,
        spacing
      );

      this.drawRuler(
        this.bottomLeftRulerCornerWCS,
        this.bottomRightRulerCornerWCS,
        this.origin,
        "horizontal",
        gradLength,
        subGradLength,
        spacing
      );
      //this.drawGrid(spacing);

      RULER_DESIGN_PROPS.textsize = Toolbox.screenDistanceToWorld(LABEL_HEIGHT);
      if (!this._entityId) return;
      EntityBuilder.setProperties(this._entityId, RULER_DESIGN_PROPS);
    }
  }

  /**
   * Draw a ruler
   * @param start start point of the ruler
   * @param end end point of the ruler
   * @param origin zero of the line
   * @param direction
   * @param gradLength length of big graduation in WCS
   * @param subGradLength length of smaller graduation in WCS
   * @param spacing spacing between (sub)graduation
   */

  drawRuler(
    start: pointArray,
    end: pointArray,
    origin: pointArray,
    direction: "vertical" | "horizontal",
    gradLength: number,
    subGradLength: number,
    spacing: number
  ) {
    let dirIndex = 0;
    if (direction === "vertical") {
      dirIndex = 1;
    }
    const gradPoint = Array.from(start) as pointArray;
    gradPoint[dirIndex] = this.computeClosestHigherStep(
      origin[dirIndex],
      start[dirIndex],
      spacing
    );

    for (
      ;
      gradPoint[dirIndex] <= end[dirIndex];
      gradPoint[dirIndex] = gradPoint[dirIndex] + spacing
    ) {
      if (
        Math.round((gradPoint[dirIndex] - origin[dirIndex]) / spacing) %
          SUB_GRADUATION_COUNT !=
        0
      ) {
        this.drawGraduation(gradPoint, subGradLength, direction);
      } else {
        this.drawGraduation(gradPoint, gradLength, direction);
        const labelPoint: pointArray = [
          gradPoint[0] - gradLength * 2 * dirIndex,
          gradPoint[1] + gradLength * 2 * Math.abs(dirIndex - 1),
          gradPoint[2],
        ];
        const str = (gradPoint[dirIndex] - origin[dirIndex]).toFixed(0);
        let placement: number;
        dirIndex ? (placement = 11) : (placement = 13);
        this.drawGraduationLabel(labelPoint, str, placement);
      }
    }
  }

  drawGraduation(
    point: pointArray,
    length: number,
    direction: "vertical" | "horizontal"
  ) {
    if (this._entityId) {
      const entity = this._entityId.openObject();
      if (direction === "vertical") {
        const poly = entity.appendPolyline(
          point.concat([point[0] - length, point[1], point[0]])
        );
        poly.delete();
      } else if (direction === "horizontal") {
        const poly = entity.appendPolyline(
          point.concat([point[0], point[1] + length, point[0]])
        );
        poly.delete();
      }
      entity.delete();
    }
  }

  /**
   * Add a text label to this.entity
   * For placement, refer to https://docs.opendesign.com/tv/OdTvTextStyle__AlignmentType.html
   *
   * @param point
   * @param label
   * @param placement
   */
  drawGraduationLabel(point: pointArray, str: string, alignment?: number) {
    if (this._entityId) {
      const data: TextData = {
        refpoint: point,
        message: str,
        alignmentmode: alignment,
      };
      TextBuilder.addText(this._entityId, data);
    }
  }

  drawGrid(spacing: number) {
    if (this._entityId && spacing) {
      const entity = this._entityId.openObject();
      const origin = [
        this.computeClosestHigherStep(
          this.origin[0],
          this.bottomLeftCanvasCornerWCS[0],
          spacing
        ),
        this.computeClosestHigherStep(
          this.origin[1],
          this.bottomRightCanvasCornerWCS[1],
          spacing
        ),
        0,
      ] as VisualizeJS.Point3;
      const firstPoint = [...origin] as VisualizeJS.Point3;

      firstPoint[0] += spacing;
      const secondPoint = [...origin] as VisualizeJS.Point3;
      const circle = entity.appendCircle(origin, firstPoint, secondPoint);
      circle.delete();
      secondPoint[1] += spacing;
      const firstCount = Math.ceil(
        (this.topRightCanvasCornerWCS[0] - this.bottomLeftCanvasCornerWCS[0]) /
          spacing
      );
      const secondCount = Math.ceil(
        (this.topRightCanvasCornerWCS[1] - this.bottomLeftCanvasCornerWCS[1]) /
          spacing
      );
      const geomId = entity.appendGrid(
        origin,
        firstPoint,
        secondPoint,
        firstCount,
        secondCount,
        LibSingleton.lib.GridDataType.kQuadratic
      );
      const grid = geomId.openAsGrid();
      grid.setStyle(LibSingleton.lib.GridDataStyle.kCrosses);
      const size = Math.min(5, MINIMUM_SPACING / 5);
      grid.setCrossesSize(size);
      grid.delete();
      geomId.delete();
    }
  }

  /**
   * Returns the first spacing value in SPACING that is greater than the MINIMUM_SPACING
   * If no spacing is found, returns 0
   */

  computeGraduationSpacing(): number {
    const distance = Toolbox.screenDistanceToWorld(MINIMUM_SPACING);
    for (const spacing of SPACINGS) {
      if (spacing >= distance) {
        return spacing;
      }
    }
    return 0;
  }

  /**
   * Computes the closest number that is a multiple of gap taking from start point
   * Returns the absiss of this number relative to absolute coordinate
   * where start and end where calculated
   * @param start
   * @param end
   * @param gap
   */
  computeClosestHigherStep(start: number, end: number, gap: number) {
    const distance = end - start;
    const numberOfSteps = Math.ceil(distance / gap);
    return numberOfSteps * gap + start;
  }

  setColor(color: [number, number, number, number]) {
    RULER_DESIGN_PROPS.color = color.slice(0, 3).toString();
  }
}
