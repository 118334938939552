<script setup lang="ts">
import { ref } from "vue";
import DRBtn from "../UI/DRBtn.vue";
import DRBtnLink from "../UI/DRBtnLink.vue";
import { usePwaController } from "../composable/usePWA";
import { TUTORIALS_URL } from "@/utils/constants";
import $ls, { LocalStorageKey } from "@/utils/local-storage";

const HIDE_ALERT_DURATION = 5 * 1000; //  24 * 60 * 60 * 1000; // 24h

const {
  needRefresh,
  reload,
  shouldChangeBrowser,
  shouldChangeBrowserAlert,
  shouldInstallPWAAlert,
  isDesktop,
} = usePwaController();

const showAlert = (
  showAlert: boolean,
  dontShowAgain: boolean,
  lastCloseDate: Date | null
) => {
  if (dontShowAgain || !showAlert) return false;
  if (!lastCloseDate) return true;
  return new Date().getTime() - lastCloseDate.getTime() > HIDE_ALERT_DURATION;
};

//BROWSER ALERT
const dontShowBrowserAlert = ref(
  $ls.getBooleanItem(LocalStorageKey.DONT_SHOW_BROWSER_ALERT)
);

const lastCloseDateBrowserAlert = ref(
  $ls.getDateItem(LocalStorageKey.BROWSER_ALERT_CLOSE_DATE)
);

const handleDontShowBrowserAlert = () => {
  shouldChangeBrowserAlert.value = false;
  $ls.setBooleanItem(LocalStorageKey.DONT_SHOW_BROWSER_ALERT, true);
  dontShowBrowserAlert.value = true;
};

const handleCloseBrowserAlert = () => {
  shouldChangeBrowserAlert.value = false;
  const now = new Date();
  $ls.setDateItem(LocalStorageKey.BROWSER_ALERT_CLOSE_DATE, now);
  lastCloseDateBrowserAlert.value = now;
};

//INSTALL ALERT
const dontShowInstallAlert = ref(
  $ls.getBooleanItem(LocalStorageKey.DONT_SHOW_INSTALL_ALERT)
);

const lastCloseDateInstallAlert = ref(
  $ls.getDateItem(LocalStorageKey.INSTALL_ALERT_CLOSE_DATE)
);

const handleDontShowInstallAlert = () => {
  shouldInstallPWAAlert.value = false;
  $ls.setBooleanItem(LocalStorageKey.DONT_SHOW_INSTALL_ALERT, true);
  dontShowInstallAlert.value = true;
};

const handleCloseInstallAlert = () => {
  shouldInstallPWAAlert.value = false;
  const now = new Date();
  $ls.setDateItem(LocalStorageKey.INSTALL_ALERT_CLOSE_DATE, now);
  lastCloseDateInstallAlert.value = now;
};
</script>

<template>
  <div v-if="needRefresh" class="overlay">
    <div class="popup">
      <div class="text-center h4 pb-2">Mise à jour disponible</div>
      <span>
        Une nouvelle version est disponible. Pour continuer, vous pouvez
        recharger la page pour mettre à jour l'application.
      </span>
      <div class="buttons">
        <DRBtn label="Recharger" @click="reload" />
      </div>
    </div>
  </div>

  <!-- SUGGEST BROWSER ALERT -->
  <div
    v-else-if="
      showAlert(
        shouldChangeBrowserAlert,
        dontShowBrowserAlert,
        lastCloseDateBrowserAlert
      )
    "
    class="overlay"
  >
    <div class="popup">
      <div class="text-center h4 pb-2">Information importante !</div>
      <span>
        Pour une expérience optimale, nous suggérons d'utiliser l'application
        sur l'un des navigateurs suivants : Chrome, Edge, Opera.
      </span>
      <div
        class="buttons"
        :style="{ justifyContent: isDesktop ? 'space-between' : 'center' }"
      >
        <DRBtnLink
          v-if="isDesktop"
          label="Ne plus afficher"
          @click="handleDontShowBrowserAlert"
        ></DRBtnLink>
        <DRBtn label="C'est compris" @click="handleCloseBrowserAlert" />
      </div>
    </div>
  </div>

  <!-- INSTALL PWA ALERT -->
  <div
    v-else-if="
      !shouldChangeBrowser &&
      showAlert(
        shouldInstallPWAAlert,
        dontShowInstallAlert,
        lastCloseDateInstallAlert
      )
    "
    class="overlay"
  >
    <div class="popup">
      <div class="text-center h4 pb-2">Installer DR</div>
      <div class="pb-3">
        Pour de meilleures performances, notamment lors de l'utilisation hors
        ligne, il est fortement recommander d'installer l'application.
      </div>
      <div class="pb-3">
        Vous trouverez une aide d'installation sur nos
        <a :href="TUTORIALS_URL" target="_blank" rel="noopener noreferrer"
          >tutoriels en ligne</a
        >
        .
      </div>
      <div>
        Si l'application a déjà été installée, vous pouvez utiliser
        l'application sans passer par le navigateur en cliquant sur l'icone DR.
      </div>
      <div
        class="buttons"
        :style="{ justifyContent: isDesktop ? 'space-between' : 'center' }"
      >
        <DRBtnLink
          v-if="isDesktop"
          label="Ne plus afficher"
          @click="handleDontShowInstallAlert"
        ></DRBtnLink>
        <DRBtn label="C'est compris" @click="handleCloseInstallAlert" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Pour s'assurer que la popup soit par-dessus tout */
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.buttons button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.fixed-badge {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: var(--bs-danger);
  color: white;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
</style>
