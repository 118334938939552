<script setup lang="ts">
import DRBtn from "@/components/UI/DRBtn.vue";
import { computed, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const isAdminRoute = computed(() => {
  return route.path === "/admin";
});

onMounted(() => {});

onUnmounted(() => {});
</script>

<template>
  <div class="d-flex justify-content-end p-5 mx-5">
    <div>
      <RouterLink v-if="isAdminRoute" to="/admin/users/create">
        <DRBtn icon="bi bi-plus" label="Ajouter un utilisateur"> </DRBtn>
      </RouterLink>
    </div>
  </div>
  <div class="mx-5 px-5">
    <RouterView />
  </div>
</template>

<style lang="css" scoped>
.round-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  position: relative;
}
</style>
