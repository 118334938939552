<script setup lang="ts">
import APIClient from "@/APIClient";
import {
  EMAIL_RG_PATTERN,
  getErrorMessage,
  PASSWORD_RG_PATTERN,
} from "@/utils/misc.utils";
import { Logger } from "@/logger";
import { onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";
import LoadingPrimaryButton from "@/components/generic/LoadingPrimaryButton.vue";
const name = ref("");
const email = ref("");
const password = ref("");
const confirmation = ref("");

const isLoading = ref(false);
const showError = ref("");

const router = useRouter();

async function register() {
  isLoading.value = true;
  try {
    await APIClient.register(name.value, email.value, password.value);
    Logger.identify();
    router.replace({ name: "admin" });
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    Logger.error(
      `Register.vue : Couldn't sign up: ${JSON.stringify(error.message)}`
    );
    showError.value = getErrorMessage(error, {
      auth: "Un compte existe déjà avec cette adresse.",
    });
  }
}

function submit() {
  if (password.value === confirmation.value) {
    register();
  } else {
    showError.value = getErrorMessage(undefined, {
      default: "Les deux mots de passe ne correspondent pas",
    });
  }
}
onMounted(() => {});

onUnmounted(() => {});
</script>

<template>
  <form class="my-4 px-5" @submit.prevent="submit" id="signup-form">
    <div class="form-floating my-3">
      <input
        v-model="name"
        type="text"
        class="form-control"
        id="name"
        placeholder="Nom et prénom"
        required
      />
      <label for="name">Nom et prénom</label>
    </div>
    <div class="form-floating my-3">
      <input
        v-model="email"
        type="email"
        class="form-control"
        id="email"
        placeholder="Adresse email"
        :pattern="EMAIL_RG_PATTERN"
        required
      />
      <label for="email">Adresse email</label>
    </div>
    <div class="form-floating mb-3">
      <input
        v-model="password"
        type="password"
        class="form-control"
        id="password"
        placeholder="Mot de passe"
        :pattern="PASSWORD_RG_PATTERN"
        required
      />
      <label for="password">Mot de passe</label>
    </div>
    <div class="form-floating mb-3">
      <input
        class="form-control my-3"
        type="password"
        id="confirmation"
        v-model="confirmation"
        title="Les mots de passe ne correspondent pas."
        placeholder="Confirmer le mot de passe"
        required
      />
      <label for="password">Confirmer le mot de passe</label>
    </div>
    <RouterLink :to="{ name: 'admin' }"
      ><button type="submit" class="btn btn-secondary me-3">Cancel</button>
    </RouterLink>
    <LoadingPrimaryButton :isLoading="isLoading">
      Créer un compte
    </LoadingPrimaryButton>
  </form>
  <div v-if="showError !== ''" class="alert alert-danger mt-3" role="alert">
    {{ showError }}
  </div>
</template>
