import { PhotoMarker } from "@/open-cloud/photoMarker";
import OneWayEntityDragger from "./OneWayEntityDragger";
import { DraggerEvents } from "../draggers.type";
import { InsertBuilder } from "@/open-cloud/builders/InsertBuilder";
import Toolbox from "@/open-cloud/builders/ODAToolbox";
import { cloneDeep } from "lodash";
import type { BlockConfig } from "@/open-cloud/builders/BlockBuilder";
import { EntityBuilder } from "@/open-cloud/builders/EntityBuilder";
import { TextBuilder, type TextData } from "@/open-cloud/builders/TextBuilder";
import OdaGeometryUtils from "@/open-cloud/builders/odaGeometry.utils";
import { ModelBuilder } from "@/open-cloud/builders/ModelBuilder";

export default class PhotoMarkerDragger extends OneWayEntityDragger {
  //@ts-ignore
  constructor(subject) {
    super(subject);
  }

  end(/*x: number, y: number*/) {
    super.end(/*x, y*/);
    this.viewer.emit(new Event(DraggerEvents.PhotoMarkerAdded));
  }

  _updateFrame(): void {
    if (
      this.endCornerWCS.length === 3 &&
      this.viewer.activePhotoMarkerId &&
      this.viewer.activePhotoMarker
    ) {
      const modelMup = ModelBuilder.findModel("ACTIVE");

      if (this.shadowId) modelMup.removeEntity(this.shadowId);

      const name = PhotoMarker.getPhotoMarkerName(
        this.viewer.activePhotoMarkerNumber
      );

      this.shadowId = PhotoMarkerDragger.createMarkerAsEntity(
        modelMup,
        name,
        this.endCornerWCS,
        this.viewer.activePhotoMarker,
        this.viewer.activePhotoMarkerNumber
      );

      this._setNoteConfigProperties(this.shadowId);
    }
  }

  static createMarkerAsEntity(
    model: VisualizeJS.TvModel,
    name: string,
    position: VisualizeJS.Point3,
    config: BlockConfig,
    num: string
  ): VisualizeJS.OdTvEntityId {
    const entId = model.appendEntity(name);
    EntityBuilder.appendGeometries(entId, config.geometries);
    const photoAttribute = config.attributes[0];
    const data: TextData = {
      ...photoAttribute.data,
      message: num,
    };
    TextBuilder.addText(entId, data);
    const tMatrix = OdaGeometryUtils.getTranslationMatrix(position);
    EntityBuilder.setModelingMatrix(entId, tMatrix);
    tMatrix.delete();
    return entId;
  }

  static createMarkerAsInsert(
    model: VisualizeJS.TvModel,
    name: string,
    position: VisualizeJS.Point3,
    config: BlockConfig,
    blockId: VisualizeJS.OdTvBlockId,
    num: string
  ): VisualizeJS.OdTvEntityId {
    const entId = model.appendInsert(blockId, name);
    const insert = entId.openObjectAsInsert();
    insert.setPosition(position);
    const photoAttribute = cloneDeep(config.attributes[0]);
    // translate photo number relative like block
    photoAttribute.data.refpoint = Toolbox.sum(
      photoAttribute.data.refpoint,
      position
    );
    InsertBuilder.addAttribute(entId, photoAttribute, num);
    return entId;
  }
}
