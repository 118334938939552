import { Logger } from "@/logger";
import LibSingleton from "../LibSingleton";
import { DeviceBuilder } from "./DeviceBuilder";

// FIXME : should type that better
export type Color = "kByLayer" | "kByBlock" | string; //R,G,B

export class ColorDefBuilder {
  static logProps(colorDef: VisualizeJS.OdTvColorDef) {
    Logger.debug(colorDef);
    const colorType = colorDef.getTypeEnum();
    Logger.debug("colorType", colorType);
    if (colorType === LibSingleton.lib.ColorType.kInherited) {
      Logger.debug("InheritedColor", colorDef.getInheritedColor());
    } else if (colorType === LibSingleton.lib.ColorType.kIndexed) {
      const index = colorDef.getIndexedColor();
      const activeDevice = LibSingleton.viewer.getActiveDevice();
      Logger.debug(
        "color index",
        index,
        activeDevice.getLogicalPalette()[index]
      );
      activeDevice.delete();
    } else if (colorType === LibSingleton.lib.ColorType.kColor) {
      Logger.debug("color : ", colorDef.getColor());
    } else if (colorType === LibSingleton.lib.ColorType.kDefault) {
      Logger.debug("color : ", colorDef.getColor());
    }
  }

  static getRGBfromString(
    color: string
  ): [number, number, number] | [number] | undefined {
    const matches = color.match(/\d{1,3}/g);
    if (matches && matches.length === 3) {
      const [R, G, B] = matches.map((str) => Number(str));
      return [R, G, B];
    } else {
      return undefined;
    }
  }

  static setColor(colorDef: VisualizeJS.OdTvColorDef, color: Color) {
    if (color === "kByLayer" || color === "kByBlock") {
      colorDef.setInheritedColor(
        LibSingleton.lib.InheritedAttribute[color].value
      );
    } else {
      const matches = this.getRGBfromString(color);
      if (matches && matches.length === 3) {
        const indexes = DeviceBuilder.findColorInPalette(
          [...matches, 0],
          DeviceBuilder.palette
        );
        if (indexes.length) {
          colorDef.setIndexedColor(indexes[0]);
          //Logger.debug("ColorDefBuilder.setColor", matches, indexes, this.viewer.deviceBuilder.palette, colorDef.getIndexedColor());
        } else {
          //Logger.debug("ColorDefBuilder.setColor", matches);
          colorDef.setColor(matches[0], matches[1], matches[2]);
        }
      } else {
        Logger.error(`No RGB value matches ${color}`);
      }
    }
  }
}
