import { Logger } from "@/logger";

export default class DraggerState {
  private static _isTransformActive: boolean;
  private static _isGestureActive: boolean;
  private static _isBaseActive: boolean;

  public static get isTransformActive(): boolean {
    return DraggerState._isTransformActive;
  }
  public static set isTransformActive(value: boolean) {
    Logger.info(`DraggerState.ts : is isTransformActive ${value}`);
    DraggerState._isTransformActive = value;
    DraggerState._isGestureActive = false;
    DraggerState._isBaseActive = !value;
  }
  public static get isGestureActive(): boolean {
    return DraggerState._isGestureActive;
  }
  public static set isGestureActive(value: boolean) {
    Logger.info(`DraggerState.ts : is isGestureActive ${value}`);
    if (!DraggerState.isTransformActive) {
      // There can be competition between Transformer and GestureManager, Transformer gets priority
      DraggerState._isGestureActive = value;
      DraggerState._isTransformActive = false;
      DraggerState._isBaseActive = !value;
    }
    //DraggerState.logStates();
  }
  public static get isBaseActive(): boolean {
    return DraggerState._isBaseActive;
  }
  public static set isBaseActive(value: boolean) {
    Logger.info(`DraggerState.ts : is isBaseActive ${value}`);
    DraggerState._isBaseActive = value;
    if (value) {
      DraggerState._isGestureActive = false;
      DraggerState._isTransformActive = false;
    }
    //DraggerState.logStates();
  }

  public static forceGestureActive() {
    // used to force gesture active, typically when 2 fingers are on screen
    DraggerState._isGestureActive = true;
    DraggerState._isTransformActive = false;
    DraggerState._isBaseActive = false;
  }

  private static logStates() {
    Logger.debug("======== Log dragger states ===============");
    Logger.debug(
      "isTransformActive",
      DraggerState._isTransformActive,
      DraggerState.isTransformActive
    );
    Logger.debug(
      "isGestureActive",
      DraggerState._isGestureActive,
      DraggerState.isGestureActive
    );
    Logger.debug(
      "isBaseActive",
      DraggerState._isBaseActive,
      DraggerState.isBaseActive
    );
  }
}
