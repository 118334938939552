import { ref, onMounted, onUnmounted, computed } from "vue";

const breakpoints = {
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
  xxl: 1600,
};

const currentSize = ref(0);

export function useResponsiveController() {
  const checkBreakpoint = () => {
    currentSize.value = window.innerWidth;
  };

  onMounted(() => {
    window.addEventListener("resize", checkBreakpoint);
    checkBreakpoint();
  });

  onUnmounted(() => {
    window.removeEventListener("resize", checkBreakpoint);
  });
}

export const useScreen = () =>
  computed(() => ({
    gt: {
      sm: currentSize.value > breakpoints.sm,
      md: currentSize.value > breakpoints.md,
      lg: currentSize.value > breakpoints.lg,
      xl: currentSize.value > breakpoints.xl,
      xxl: currentSize.value > breakpoints.xxl,
    },
    lt: {
      sm: currentSize.value < breakpoints.sm,
      md: currentSize.value < breakpoints.md,
      lg: currentSize.value < breakpoints.lg,
      xl: currentSize.value < breakpoints.xl,
      xxl: currentSize.value < breakpoints.xxl,
    },
  }));
