import { type BlockConfig } from "@/open-cloud/builders/BlockBuilder";
// scale must be 1.
export const DR_BLOCKS: BlockConfig[] = [
  {
    name: "Rejet",
    scale: 1,
    geometries: [
      {
        type: "circle",
        center: [-1, 0, 0],
        radius: 0.5,
      },
      {
        type: "circle",
        center: [-1, 0, 0],
        radius: 0.05,
        filled: true,
      },
      {
        type: "circle",
        center: [1, 0, 0],
        radius: 0.5,
      },
      {
        type: "polyline",
        points: [
          [0.5, 0, 0],
          [1.5, 0, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [1, 0.5, 0],
          [1, -0.5, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Végétation",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [-1, 0, 0],
          [-1, 0.3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-1, 0, 0],
          [-1.2, 0.2, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-1, 0, 0],
          [-0.8, 0.2, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [1, 0, 0],
          [1, 0.3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [1, 0, 0],
          [1.2, 0.2, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [1, 0, 0],
          [0.8, 0.2, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0, 1, 0],
          [0, 1.3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0, 1, 0],
          [0.2, 1.2, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0, 1, 0],
          [-0.2, 1.2, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Humidité",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [-0.6, -0.4, 0],
          [0.4, 0.6, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-0.4, -0.6, 0],
          [0.6, 0.4, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-0.6, 0.4, 0],
          [0.4, -0.6, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-0.4, 0.6, 0],
          [0.6, -0.4, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "AAO",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [-1, 0.3, 0],
          [-1, -0.3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [1, 0.3, 0],
          [1, -0.3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-1, 0, 0],
          [1, 0, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Eclat",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [-1.2, 0, 0],
          [-0.8, 0.2, 0],
          [-0.6, 0.8, 0],
          [-0.4, 0.4, 0],
          [0, 0.8, 0],
          [0.3, 0.4, 0],
          [0.6, 0.8, 0],
          [0.8, 0.5, 0],
          [1.2, 0, 0],
          [0.8, -0.5, 0],
          [0.6, -0.8, 0],
          [0.3, -0.4, 0],
          [0, -0.8, 0],
          [-0.4, -0.4, 0],
          [-0.6, -0.8, 0],
          [-0.8, -0.2, 0],
          [-1.2, 0, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Eclat avec acier apparent",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [-1.2, 0, 0],
          [-0.8, 0.2, 0],
          [-0.6, 0.8, 0],
          [-0.4, 0.4, 0],
          [0, 0.8, 0],
          [0.3, 0.4, 0],
          [0.6, 0.8, 0],
          [0.8, 0.5, 0],
          [1.2, 0, 0],
          [0.8, -0.5, 0],
          [0.6, -0.8, 0],
          [0.3, -0.4, 0],
          [0, -0.8, 0],
          [-0.4, -0.4, 0],
          [-0.6, -0.8, 0],
          [-0.8, -0.2, 0],
          [-1.2, 0, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-0.7, 0.3, 0],
          [-0.7, -0.3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0.7, 0.3, 0],
          [0.7, -0.3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-0.7, 0, 0],
          [0.7, 0, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Amorce d'éclat",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [-1, -0.5, 0],
          [-1, 0.5, 0],
          [1, 0.5, 0],
          [1, -0.5, 0],
          [-1, -0.5, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-1, -0.5, 0],
          [1, 0.5, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [1, -0.5, 0],
          [-1, 0.5, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Eclat 2",
    scale: 1,
    geometries: [
      {
        type: "circle",
        center: [0, 0, 0],
        radius: 0.6,
      },
      {
        type: "circle",
        center: [0, 0, 0],
        radius: 0.4,
      },
    ],
    attributes: [],
  },
  {
    name: "Eclat 2 avec acier apparent",
    scale: 1,
    geometries: [
      {
        type: "circle",
        center: [0, 0, 0],
        radius: 0.6,
      },
      {
        type: "circle",
        center: [0, 0, 0],
        radius: 0.4,
      },
      {
        type: "polyline",
        points: [
          [-0.7, 0.3, 0],
          [-0.7, -0.3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0.7, 0.3, 0],
          [0.7, -0.3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-0.7, 0, 0],
          [0.7, 0, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Rouille",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [-0.6, 0.4, 0],
          [-0.9, -0.4, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-0.3, 0.4, 0],
          [-0.6, -0.4, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0, 0.4, 0],
          [-0.3, -0.4, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0.3, 0.4, 0],
          [0, -0.4, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0.6, 0.4, 0],
          [0.3, -0.4, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0.9, 0.4, 0],
          [0.6, -0.4, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Stalactite",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [0, 0.5, 0],
          [0, 0.4, 0],
          [-0.2, 0.3, 0],
          [0.2, 0.1, 0],
          [-0.1, -0.1, 0],
          [0, -0.3, 0],
          [0, -0.4, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Point",
    scale: 1,
    geometries: [
      {
        type: "circle",
        center: [0, 0, 0],
        radius: 0.3,
        filled: true,
      },
    ],
    attributes: [],
  },
  {
    name: "Flèche",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [-1, 0.4, 0],
          [0.4, 0.4, 0],
          [0.4, 0.8, 0],
          [1, 0, 0],
          [0.4, -0.8, 0],
          [0.4, -0.4, 0],
          [-1, -0.4, 0],
          [-1, 0.4, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Concrétion-sèche-1",
    scale: 1,
    geometries: [
      {
        type: "circle",
        center: [-0.5, -0.5, 0],
        radius: 0.5,
        filled: false,
      },
      {
        type: "circle",
        center: [0.5, -0.5, 0],
        radius: 0.5,
        filled: false,
      },
      {
        type: "circle",
        center: [0.5, 0.5, 0],
        radius: 0.5,
        filled: false,
      },
      {
        type: "circle",
        center: [-0.5, 0.5, 0],
        radius: 0.5,
        filled: false,
      },
    ],
    attributes: [],
  },
  {
    name: "Concrétion-humide-1",
    scale: 1,
    geometries: [
      {
        type: "circle",
        center: [-0.5, -0.5, 0],
        radius: 0.5,
        filled: false,
      },
      {
        type: "circle",
        center: [0.5, -0.5, 0],
        radius: 0.5,
        filled: false,
      },
      {
        type: "circle",
        center: [0.5, 0.5, 0],
        radius: 0.5,
        filled: false,
      },
      {
        type: "circle",
        center: [-0.5, 0.5, 0],
        radius: 0.5,
        filled: false,
      },
      {
        type: "polyline",
        points: [
          [-0.25, -0.5, 0],
          [-0.35, -1, 0],
          [-0.15, -2, 0],
          [-0.35, -3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-0.75, -0.5, 0],
          [-0.85, -1, 0],
          [-0.65, -2, 0],
          [-0.85, -3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0.25, -0.5, 0],
          [0.15, -1, 0],
          [0.35, -2, 0],
          [0.15, -3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0.75, -0.5, 0],
          [0.65, -1, 0],
          [0.85, -2, 0],
          [0.65, -3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [1.25, 0, 0],
          [1.15, -0.5, 0],
          [1.35, -1.5, 0],
          [1.15, -2.5, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [1.75, 0.5, 0],
          [1.65, 0, 0],
          [1.85, -1, 0],
          [1.65, -2, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-1.25, 0, 0],
          [-1.15, -0.5, 0],
          [-1.35, -1.5, 0],
          [-1.15, -2.5, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-1.75, 0.5, 0],
          [-1.65, 0, 0],
          [-1.85, -1, 0],
          [-1.65, -2, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Stalactite-sèche-1",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [-1, 1, 0],
          [1, 1, 0],
          [0.25, 0, 0],
          [0, -1, 0],
          [-0.25, 0, 0],
          [-1, 1, 0],
        ],
      },
    ],
    attributes: [],
  },
  {
    name: "Stalactite-humide-1",
    scale: 1,
    geometries: [
      {
        type: "polyline",
        points: [
          [-1, 1, 0],
          [1, 1, 0],
          [0.25, 0, 0],
          [0, -1, 0],
          [-0.25, 0, 0],
          [-1, 1, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-0.25, -0.5, 0],
          [-0.35, -1, 0],
          [-0.15, -2, 0],
          [-0.35, -3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-0.75, -0.5, 0],
          [-0.85, -1, 0],
          [-0.65, -2, 0],
          [-0.85, -3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0.25, -0.5, 0],
          [0.15, -1, 0],
          [0.35, -2, 0],
          [0.15, -3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [0.75, -0.5, 0],
          [0.65, -1, 0],
          [0.85, -2, 0],
          [0.65, -3, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [1.25, 0, 0],
          [1.15, -0.5, 0],
          [1.35, -1.5, 0],
          [1.15, -2.5, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [1.75, 0.5, 0],
          [1.65, 0, 0],
          [1.85, -1, 0],
          [1.65, -2, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-1.25, 0, 0],
          [-1.15, -0.5, 0],
          [-1.35, -1.5, 0],
          [-1.15, -2.5, 0],
        ],
      },
      {
        type: "polyline",
        points: [
          [-1.75, 0.5, 0],
          [-1.65, 0, 0],
          [-1.85, -1, 0],
          [-1.65, -2, 0],
        ],
      },
    ],
    attributes: [],
  },
];
