import { Logger } from "@/logger";
import { EntityBuilder, type EntityProps } from "../builders/EntityBuilder";
import { ModelBuilder } from "../builders/ModelBuilder";
import Command from "./Command";
import { LayerBuilder, type Layer } from "../builders/LayerBuilder";
import { v4 } from "uuid";
import Toolbox from "../builders/ODAToolbox";
type PerformanceModeParam = {
  status: boolean;
};

const MERGER_NAME_PREFIX = "DR_MERGER_ENTITY";

export const MERGER_LAYER: Layer = {
  name: "__DR_MERGER",
  color: "128,128,128",
};

export default class TogglePerformanceMode extends Command<PerformanceModeParam> {
  static isOn: boolean;
  protected _execute(params: PerformanceModeParam) {
    // TODO : init merger at drawing open and not at each toggle
    TogglePerformanceMode.isOn = params.status;
    if (TogglePerformanceMode.isOn) {
      this._initMerger();
    } else {
      this._deleteMerger();
    }
    this._setEntitiesVisibility(!params.status, params.status);
    this._viewer.update();
  }

  protected _reexecute(params: PerformanceModeParam) {
    this._execute(params);
  }

  protected _unexecute(params: PerformanceModeParam) {
    // just invert status
    this._execute({
      status: !params.status,
    });
  }

  private _initMerger() {
    Logger.debug("start init merger");
    this._deleteMerger();
    LayerBuilder.putLayer(MERGER_LAYER);
    this._copyEntitiesToMerger();
    Logger.debug("done init merger");
  }

  private _deleteMerger() {
    const model = ModelBuilder.getModel("ACTIVE");
    const itr = model.getEntitiesIterator();
    for (; !itr.done(); itr.step()) {
      const entId = itr.getEntity();
      if (TogglePerformanceMode.isMerger(entId)) {
        model.removeEntity(entId);
      }
      entId.delete();
    }
    itr.delete();
  }

  private _appendMerger(
    blockId?: VisualizeJS.OdTvBlockId
  ): VisualizeJS.OdTvEntityId {
    let entId: VisualizeJS.OdTvEntityId;
    const name = `${MERGER_NAME_PREFIX}_${v4()}`;
    if (blockId) {
      entId = ModelBuilder.createInsert(name, blockId, "ACTIVE");
    } else {
      entId = ModelBuilder.createEntity(name, "ACTIVE");
    }
    return entId;
  }

  private _copyEntitiesToMerger() {
    const model = ModelBuilder.getModel("ACTIVE");
    const itr = model.getEntitiesIterator();
    const copyId = this._appendMerger();
    const props: EntityProps = {
      color: "kByLayer",
      layername: MERGER_LAYER.name,
    };
    EntityBuilder.setProperties(copyId, props);
    for (; !itr.done(); itr.step()) {
      const entId = itr.getEntity();
      if (
        EntityBuilder.isNative(entId) &&
        !EntityBuilder.isInTempLayer(entId)
      ) {
        if (entId.getType() === 1) {
          EntityBuilder.copyGeometryTo(entId, copyId);
        } else if (entId.getType() === 2) {
          // entId is an insert. copying an insert to an entity does not work
          // then we create an independant merger of the same block
          const ent = entId.openObjectAsInsert();
          const blockId = ent.getBlock();
          const mergerInsertId = this._appendMerger(blockId);
          EntityBuilder.copyGeometryTo(entId, mergerInsertId);
          // put it in merger layer
          EntityBuilder.setProperties(mergerInsertId, props);
          Toolbox.deleteAll([ent, blockId, mergerInsertId]);
        } else {
          Logger.warn(
            `TogglePerformanceMode._copyEntitiesToMerger : type is not 1 or 2`
          );
        }
      }
      entId.delete();
    }
    itr.delete();
    copyId.delete();
  }

  private _setEntitiesVisibility(
    visibility: boolean,
    mergerVisibility: boolean
  ) {
    const model = ModelBuilder.getModel("ACTIVE");
    const itr = model.getEntitiesIterator();
    for (; !itr.done(); itr.step()) {
      const entId = itr.getEntity();
      if (EntityBuilder.isNative(entId)) {
        EntityBuilder.setVisible(entId, visibility);
      } else if (TogglePerformanceMode.isMerger(entId)) {
        EntityBuilder.setVisible(entId, mergerVisibility);
      }
      entId.delete();
    }
    itr.delete();
  }

  static isMerger(entId: VisualizeJS.OdTvEntityId): boolean {
    const name = EntityBuilder.getName(entId);
    return name.startsWith(MERGER_NAME_PREFIX);
  }

  dispose(): void {
    this._deleteMerger();
  }
}
