import { Logger } from "@/logger";
import { type Attribute } from "./BlockBuilder";
import { EntityBuilder, type EntityProps } from "./EntityBuilder";
import OdaGeometryUtils from "./odaGeometry.utils";
import { TextBuilder } from "./TextBuilder";
import Toolbox from "./ODAToolbox";

export class InsertBuilder {
  static setBlockMatrix(
    entId: VisualizeJS.OdTvEntityId,
    matrix: VisualizeJS.Matrix3d
  ) {
    const insert = entId.openObjectAsInsert();
    const old = insert.getBlockTransform();
    old.invert();
    const delta = old.preMultBy(matrix);
    insert.setBlockTransform(matrix);
    const itr = insert.getSubEntitiesIterator();
    for (; !itr.done(); itr.step()) {
      const entId = itr.getEntity();
      const baseMatrix = EntityBuilder.getModelingMatrix(entId);
      baseMatrix.preMultBy(delta);
      EntityBuilder.setModelingMatrix(entId, baseMatrix);
      baseMatrix.delete();
      entId.delete();
    }
    itr.delete();
    delta.delete();
    old.delete();
  }

  static getBlockMatrix(entId: VisualizeJS.OdTvEntityId): VisualizeJS.Matrix3d {
    const insert = entId.openObjectAsInsert();
    const matrix = insert.getBlockTransform();
    insert.delete();
    return matrix;
  }

  static addAttribute(
    insertId: VisualizeJS.OdTvEntityId,
    attribute: Attribute,
    message: string
  ) {
    const insert = insertId.openObjectAsInsert();
    const entId = insert.appendSubEntity(attribute.name);
    TextBuilder.addText(entId, { ...attribute.data, message });
    insert.delete();
    entId.delete();
  }

  // does not work at the moment
  static setUniformScaleFactor(entId: VisualizeJS.OdTvEntityId, scale: number) {
    const insert = entId.openObjectAsInsert();
    insert.setScaleFactors([scale, scale, 1]);
    insert.delete();
  }

  static scaleInsert(entId: VisualizeJS.OdTvEntityId, scale: number) {
    const initialScale = 1;
    const oldMatrix = EntityBuilder.getModelingMatrix(entId);
    const newMatrix = OdaGeometryUtils.reScaleMatrix(
      oldMatrix,
      scale,
      initialScale
    );
    EntityBuilder.setModelingMatrix(entId, newMatrix);
  }

  static isInsert(entId: VisualizeJS.OdTvEntityId) {
    return entId.getType() === 2;
  }

  static getSubEntityTextProps(
    insertId: VisualizeJS.OdTvEntityId
  ): Required<Pick<EntityProps, "textsize" | "textstyle">>[] {
    if (insertId.getType() === 2) {
      const insert = insertId.openObjectAsInsert();
      const itr = insert.getSubEntitiesIterator();
      const textProps = [];
      for (; !itr.done(); itr.step()) {
        const subentId = itr.getEntity();
        textProps.push(...EntityBuilder.getTextProps(subentId));
        subentId.delete();
      }
      Toolbox.deleteAll([itr, insert]);
      return textProps;
    } else {
      Logger.warn(`insertBuilder.getSubEntityTextProps : entity type is not 2`);
      return [];
    }
  }

  static getScale(insertId: VisualizeJS.OdTvEntityId): number | undefined {
    if (insertId.getType() === 2) {
      const insert = insertId.openObjectAsInsert();
      const scale = insert.getScaleFactors();
      insert.delete();
      return scale[0];
    } else {
      Logger.warn(`InsertBuilder.computeScale : entity is not of type 2`);
      return undefined;
    }
  }

  static setVisibility(
    insertId: VisualizeJS.OdTvEntityId,
    visibility: boolean
  ) {
    const insert = insertId.openObjectAsInsert();
    insert.setVisibility(visibility);
    insert.delete();
  }
  /**
   * Copy properties of an insert to another
   * @param insertId must be type 2
   * @param targetId must be type 2
   */
  static copyToInsert(
    insertId: VisualizeJS.OdTvEntityId,
    targetId: VisualizeJS.OdTvEntityId
  ) {
    const insert = insertId.openObjectAsInsert();
    const target = targetId.openObjectAsInsert();
    target.setPosition(insert.getPosition());
    const itr = insert.getSubEntitiesIterator();
    for (; !itr.done(); itr.step()) {
      const subentId = itr.getEntity();
      const subent = subentId.openObject();
      const cloneId = target.appendSubEntity(subent.getName());
      subent.copyTo(cloneId);
      Toolbox.deleteAll([subentId, subent, cloneId]);
    }
    itr.delete();

    target.setRotation(insert.getRotation());
    target.setNormal(insert.getNormal());
    const matrix = insert.getBlockTransform();
    target.setBlockTransform(matrix);
    const colorDef = insert.getColor();
    target.setColor(colorDef);
    target.setContentsSelectable(insert.getContentsSelectable());
    //  does not work... but it is not a big deal
    //target.setVisibility(insert.getVisibility());
    target.setExtents(insert.getExtents().ext);
    target.setScaleFactors(insert.getScaleFactors());
    const layerId = insert.getLayer();
    target.setLayer(layerId);
    Toolbox.deleteAll([insert, target, colorDef, matrix, layerId]);
  }
}
