import { createRouter, createWebHistory } from "vue-router";
import FileListView from "./views/FileListView.vue";
import DrawingCanvasView from "./views/DrawingCanvas.vue";
import Welcome from "./views/Welcome.vue";
import Login from "./components/welcome/Login.vue";
import UserState from "@/stores/UserState";
import ArchiveListView from "@/views/ArchiveListView.vue";
import Forgot from "@/components/welcome/Forgot.vue";
import Reset from "@/components/welcome/Reset.vue";
import Administration from "./views/Administration.vue";
import UserList from "./components/admin/UserList.vue";
import UserRegistration from "./components/admin/UserRegistration.vue";
import SignUp from "./components/welcome/SignUp.vue";
import AuthLayout from "./views/layouts/AuthLayout.vue";
import CanvaLayout from "./views/layouts/CanvaLayout.vue";

export const noAuthRoutePrefix = "/bienvenue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: noAuthRoutePrefix,
      redirect: `${noAuthRoutePrefix}/se-connecter`,
      name: "welcome",
      component: Welcome,

      meta: {
        forVisitors: true,
      },
      children: [
        {
          path: "se-connecter",
          name: "login",
          component: Login,
        },
        {
          path: "creer-un-compte",
          name: "signup",
          component: SignUp,
        },
        {
          path: "recuperation-de-mot-de-passe",
          name: "forgot",
          alias: "forgot",
          component: Forgot,
        },
        {
          path: "nouveau-mot-de-passe",
          name: "reset",
          component: Reset,
          props: (route) => ({ token: route.query.token }),
        },
      ],
    },
    // redirect. `/reset` will be in email templates. Therefore, the human-readable path in French can be updated without breaking emails
    {
      path: "/",
      component: AuthLayout,
      redirect: { name: "home" },
      meta: {
        forUsers: true,
      },
      children: [
        { path: "/reset", redirect: { name: "reset" } },
        {
          path: "/projects",
          name: "home",
          component: FileListView,

          meta: {
            title: "Mes projets",
          },
        },
        {
          path: "/admin",
          component: Administration,
          children: [
            {
              path: "",
              name: "admin",
              component: UserList,
              meta: {
                title: "Organisation",
              },
            },
            {
              path: "users/create",
              component: UserRegistration,
              meta: {
                title: "Ajouter un utilisateur",
              },
            },
          ],
        },
        {
          path: "/archives",
          name: "archives",
          component: ArchiveListView,

          meta: {
            title: "Archives",
          },
        },
      ],
    },
    {
      path: "/dessin",
      component: CanvaLayout,
      meta: {
        forUsers: true,
      },
      children: [
        {
          path: "/dessin/:drawingId?",
          name: "canvas",
          component: DrawingCanvasView,

          props: (route) => {
            /**
             * This would preserve the other route.params object properties overriding only
             * `userId` in case it exists with its integer equivalent, or otherwise with
             * undefined.
             */
            return {
              ...route.params,
              // drawingId: route.params.drawingId,
              drawingId:
                Number.parseInt(route.params.drawingId, 10) || undefined,
            };
          },
        },
      ],
    },
  ],
  scrollBehavior(/*to, from, savedPosition*/) {
    return { top: 0 }; // To get on top of the page especially for canvas
  },
});

router.beforeEach(async (to) => {
  //console.clear(); // release console ref to Objects

  // redirect users if they try to access a route for which they need to be logged in
  const needLoggedIn = to.matched.some((record) => record.meta.forUsers);
  if (!UserState.isLoggedIn && needLoggedIn) {
    return { name: "welcome" };
  }

  // redirects users that are already logged in to home
  const needLoggedOut = to.matched.some((record) => record.meta.forVisitors);
  if (UserState.isLoggedIn && needLoggedOut) {
    return { name: "home" };
  }
});

export default router;
