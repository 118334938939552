import { reactive, toRaw } from "vue";
import { DEFAULT_LEGEND } from "../defaultLegend";
import type { Legend } from "./UserState";
import Settings from "@/stores/Settings";
import { Facade } from "../open-cloud/Facade";
import _ from "lodash";
import { Logger } from "../logger";
import ScaleMenuState from "./ScaleMenuState";
import NoteMenuState from "./NoteMenuState";
import DraggerMenuState from "./DraggerMenuState";
import type { DraggerName } from "../open-cloud/draggers/draggers.type";
import DraggerOptionState from "./DraggerOptionState";
export type StoredSettings = {
  legendIdx?: number;
  scale?: {
    name?: string;
    isTextsizeForced?: boolean;
    forcedSize?: number | "";
  };
  note?: {
    activeCategories?: string[];
    activeNoteIdx?: number;
    previousNoteIdx?: number;
  };
  dragger?: {
    name?: DraggerName;
    photoMarkerShape?: "arrow" | "circle";
    label?: boolean;
    origin?: [number, number, number];
    closeContour?: boolean;
    smoothen?: boolean;
    activeBlock?: {
      name: string;
    };
  };
};

const LOCAL_STORAGE_CANVAS_SETTINGS_KEY = "canvas-settings";

type CanvasSettingState = {
  drawingId: number;
  legends: Legend[];
  activeLegend: Legend;
  storedSettings: StoredSettings | null;
  performanceModeOn: boolean;
  initialize: (drawingId: number, legends: Legend[]) => void;
  onDrawingOpen: () => void;
  onLegendChange: (legend: Legend) => void;
  initLegend: () => void;
  correctLegendColors: (legend: Legend) => void;
  setActiveLegend: (legend: Legend) => void;
  isDrawingOpen: () => boolean;
  updateAndPersist: () => void;
  setPerformanceMode: (b: boolean) => void;
  togglePerformanceMode: () => void;
  reset: () => void;
};

export default reactive<CanvasSettingState>({
  drawingId: -1,
  legends: [],
  activeLegend: DEFAULT_LEGEND,
  storedSettings: null,
  performanceModeOn: false,
  initialize(drawingId: number, legends: Legend[]) {
    this.drawingId = drawingId;
    this.legends = legends;
    const data = localStorage.getItem(LOCAL_STORAGE_CANVAS_SETTINGS_KEY);
    if (data) {
      this.storedSettings = JSON.parse(data) as StoredSettings;
      ScaleMenuState.initialize(drawingId, this.storedSettings);
      NoteMenuState.initialize(this.storedSettings);
      DraggerMenuState.initialize(this.storedSettings);
      DraggerOptionState.initialize(this.storedSettings);
    } else {
      ScaleMenuState.initialize(drawingId, null);
    }
  },
  async onDrawingOpen() {
    this.initLegend(); // start by setting legend

    // Then set scale menu
    ScaleMenuState.onDrawingOpen(this.activeLegend.scale_configs);
    NoteMenuState.onDrawingOpen(this.activeLegend.note_configs);
    // update note config to take into account the previous changes
    NoteMenuState.updateComputedNoteConfig();
    DraggerMenuState.onDrawingOpen();
    DraggerOptionState.onDrawingOpen();
    //this.setPerformanceMode(false);
  },
  initLegend() {
    Logger.info(`CanvasSettingsState.ts : init legend menu`);
    if (
      this.storedSettings?.legendIdx &&
      this.legends[this.storedSettings?.legendIdx]
    ) {
      this.setActiveLegend(this.legends[this.storedSettings?.legendIdx]);
    } else {
      if (this.legends.length) {
        this.setActiveLegend(this.legends[0]);
      } else {
        this.setActiveLegend(DEFAULT_LEGEND);
      }
    }
  },
  setActiveLegend(legend: Legend) {
    this.activeLegend = legend;
    const legendIdx = this.legends.findIndex(
      (legend) => legend == this.activeLegend
    );
    // FIXME : How to remove casting here ?
    this.storedSettings = _.set(
      this.storedSettings || {},
      "legendIdx",
      legendIdx
    ) as StoredSettings;
    // change colors that are similar to the theme
    this.correctLegendColors(this.activeLegend);
    Facade.setActiveLayers(toRaw(legend.layer_configs));
  },
  onLegendChange(legend: Legend) {
    this.setActiveLegend(legend);
    ScaleMenuState.onLegendChange(legend.scale_configs);
    NoteMenuState.onLegendChange(legend.note_configs);
    //NoteMenuState.updateComputedNoteConfig();
    this.updateAndPersist();
  },
  correctLegendColors(legend: Legend) {
    const themeColor = Settings.actviveTheme.bgColor.slice(0, 3).toString();
    const themeOverrideColor = Settings.actviveTheme.overrideColor
      .slice(0, 3)
      .toString();
    for (const note_config of legend.note_configs) {
      if (note_config.props.color && note_config.props.color === themeColor) {
        note_config.props.color = themeOverrideColor;
      }
    }
    for (const layer_config of legend.layer_configs) {
      if (layer_config.color && layer_config.color === themeColor) {
        layer_config.color = themeOverrideColor;
      }
    }
  },
  isDrawingOpen() {
    return Facade.isDrawingOpen;
  },
  /**
   * Add textsize to computed config while keeping the textstyle,
   * @param height undefined is used to unforce
   * @returns
   */
  updateAndPersist() {
    if (this.storedSettings) {
      localStorage.setItem(
        LOCAL_STORAGE_CANVAS_SETTINGS_KEY,
        JSON.stringify(this.storedSettings)
      );
    }
  },
  setPerformanceMode(b) {
    this.performanceModeOn = b;
    Facade.setPerformanceModeOn(b);
  },
  togglePerformanceMode() {
    this.setPerformanceMode(!toRaw(this.performanceModeOn));
  },
  reset() {
    this.storedSettings = {};
    this.updateAndPersist();
  },
});
