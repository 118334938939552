import { EntityBuilder } from "../builders/EntityBuilder";
import { ModelBuilder } from "../builders/ModelBuilder";
import Command from "./Command";

export type EditTextParam = {
  handle: string;
  content: string;
  oldContent: string;
};

export default class EditTextsCommand extends Command<EditTextParam[]> {
  execute(params: EditTextParam[], shouldStore = true): void {
    this._replaceTexts(params, false);
    super.execute(params, shouldStore);
    this._viewer.update();
  }

  _reexecute(params: EditTextParam[]) {
    this._replaceTexts(params, false);
    this._viewer.update();
  }

  _unexecute(params: EditTextParam[]) {
    this._replaceTexts(params, true);
    this._viewer.update();
  }
  // replace the text of an entity
  // Returns the old params
  protected _replaceText(entId: VisualizeJS.OdTvEntityId, message: string) {
    if (entId && EntityBuilder.countTextGeometries(entId) != 0) {
      // clear geometries
      const ent = entId?.openObject();
      EntityBuilder.editText(entId, message);
      ent.delete();
    }
  }

  protected _replaceTexts(params: EditTextParam[], useOld: boolean) {
    for (const param of params) {
      const entId = ModelBuilder.getEntityByHandle(param.handle);
      if (!entId || entId.isNull()) break;
      if (useOld) {
        this._replaceText(entId, param.oldContent);
      } else {
        this._replaceText(entId, param.content);
      }
      entId?.delete();
    }
  }
}
