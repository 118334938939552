<script setup lang="ts">
import { useLongPressTooltip } from "@/components/composable/useLongPressTooltip";
import type { FileStoreDrawingFile } from "@/stores/fileStore";
import { Monitor } from "@/utils/monitor";
import { formatDistanceToNow } from "@/utils/misc.utils";
import { ref, onMounted, onUnmounted } from "vue";
const props = defineProps<{
  drawing: FileStoreDrawingFile;
}>();
const { refHook: refHookSyncWarning } = useLongPressTooltip();
const { refHook: refHookSynced } = useLongPressTooltip();
// Last edit label
const lastEditMonitor = new Monitor(
  () => {
    if (props.drawing.lastRevisionEditedAt) {
      editedAt.value = formatEditAt();
    }
  },
  { intervalInMs: 2000 }
);
const formatEditAt = () =>
  props.drawing.lastRevisionEditedAt &&
  formatDistanceToNow(props.drawing.lastRevisionEditedAt);

const editedAt = ref(formatEditAt());

onMounted(() => {
  lastEditMonitor.start();
});

onUnmounted(() => {
  lastEditMonitor.stop();
});
</script>
<template>
  <div class=" ">
    <small class="text-muted align-self-center text-truncate" v-if="editedAt">
      <span class="me-2" style="font-size: 10px"> Édité {{ editedAt }}</span>
      <span v-if="drawing.isSynced" class="text-success align-self-center">
        <i
          ref="refHookSynced"
          data-bs-content="Ce fichier est synchronisé avec le serveur"
          class="bi bi-check2"
        ></i>
      </span>
      <span v-else class="text-warning align-self-center">
        <i
          ref="refHookSyncWarning"
          data-bs-content="Ce fichier contient un brouillon non synchronisé avec le serveur"
          class="bi bi-exclamation-circle-fill"
        ></i>
      </span>
    </small>
  </div>
</template>
