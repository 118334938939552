<script setup lang="ts">
import DrawingList from "@/components/files/DrawingList/DrawingList.vue";
import EmptyFileList from "@/components/files/EmptyFileList.vue";
import { useDrawingStore } from "@/stores/fileStore";
import FileActionButtons from "@/components/files/FileActionButtons.vue";
import { onMounted, onUnmounted } from "vue";
import { useDeviceState } from "@/stores/DeviceState";
import { checkVersion } from "@/version";
const drawingStore = useDrawingStore();
const deviceState = useDeviceState();

onMounted(() => {
  deviceState.startMonitoringOnlineState();
  drawingStore.startAutoSync();
  checkVersion();
});

onUnmounted(() => {
  deviceState.stopMonitoringOnlineState();
  drawingStore.stopAutoSync();
});
</script>

<template>
  <div class="my-5 mx-1 mx-sm-2 mx-md-4 mx-lg-5 px-sm-2 px-md-4 px-lg-5">
    <div v-if="drawingStore.drawings && drawingStore.drawings.length > 0">
      <FileActionButtons />
      <DrawingList :drawings="drawingStore.drawings" />
    </div>
    <div v-else>
      <EmptyFileList />
    </div>
  </div>
</template>
