import type { HatchPattern } from "./HatchBuilder";
import HatchBuilder from "./HatchBuilder";
import type { EllipseData } from "./GeometryBuilder";
import Toolbox from "./ODAToolbox";
import OdaGeometryUtils from "./odaGeometry.utils";
import LibSingleton from "../LibSingleton";

export default class ShellBuilder {
  // Ellipse does not exist as a shell, so we mock an ellipse with perimiter points
  static appendEllipseShell(
    entId: VisualizeJS.OdTvEntityId,
    data: EllipseData
  ): VisualizeJS.OdTvGeometryDataId {
    const R0 = Toolbox.computeDistance2D(data.center, data.majorPoint);
    const Rn = Toolbox.computeDistance2D(data.center, data.minorPoint);
    const points = OdaGeometryUtils.getEllipsePerimeterPoints(
      data.center,
      R0,
      Rn,
      36
    );

    const flattenPoints: number[] = [];
    const faces: number[] = [points.length];

    for (let i = 0; i < points.length; i++) {
      faces.push(i);
      flattenPoints.push(...points[i]);
    }
    const ent = entId.openObject();
    const geomId = ent.appendShell(flattenPoints, faces);
    ent.delete();
    return geomId;
  }

  static appendRectangleShell(
    entId: VisualizeJS.OdTvEntityId,
    corner1: VisualizeJS.Point3,
    corner2: VisualizeJS.Point3
  ): VisualizeJS.OdTvGeometryDataId {
    const { bottomLeft, bottomRight, topLeft, topRight } =
      OdaGeometryUtils.computeRectangleCorners(corner1, corner2);
    const faces = [4, 0, 1, 2, 3];
    // use trigonometric direction
    const flattenPoints = [
      ...bottomLeft,
      ...bottomRight,
      ...topRight,
      ...topLeft,
    ];
    const ent = entId.openObject();
    const geomId = ent.appendShell(flattenPoints, faces);
    ent.delete();
    return geomId;
  }

  static appendPolylineShell(
    entId: VisualizeJS.OdTvEntityId,
    points: number[]
  ) {
    const remainder = points.length % 3;
    if (remainder != 0)
      throw new TypeError(
        `ShellBuilder.appendPolylineShell : point count is wrong`
      );
    const count = Math.floor(points.length / 3);
    const faces = [count];
    for (let i = 0; i < count; i++) faces.push(i);
    const ent = entId.openObject();
    const geomId = ent.appendShell(points, faces);
    ent.delete();
    return geomId;
  }

  static setNormal(
    shellId: VisualizeJS.OdTvGeometryDataId,
    normal: VisualizeJS.Point3,
    count: number
  ) {
    const shell = shellId.openAsShell();
    //console.log("Face Range", shell.getFaceNormalsViaRange(0, 1)); // []
    //console.log("default", shell.getFaceNormal(0)); // [0,0,0]
    //console.log(" FaceList", shell.getFaceNormalsViaList([0, 1])); // []
    /*shell.setFaceNormalViaList([0, 1], normal);
    console.log("Face Range", shell.getFaceNormalsViaRange(0, 1)); // []
    console.log("default", shell.getFaceNormal(0)); // [0,0,0]
    console.log("Face List", shell.getFaceNormalsViaList([0, 1])); // []
    shell.setFaceNormalViaRange(0, 1, normal);
    console.log("Face Range", shell.getFaceNormalsViaRange(0, 1)); // [[0,0,1]]
    console.log("default", shell.getFaceNormal(0)); // [0,0,1]
    console.log("Face List", shell.getFaceNormalsViaList([0, 1])); // []
    shell.setVertexNormalViaList([0, 1], normal);
    console.log("Vertex List", shell.getVertexNormalsViaList([0, 1])); //
    console.log("Vertex Range", shell.getVertexNormalsViaRange(0, 1)); //*/
    shell.setVertexNormalViaRange(0, count, normal);
    //console.log("Vertex List", shell.getVertexNormalsViaList([0, 1, 2, 3])); //
    //console.log("Vertex Range", shell.getVertexNormalsViaRange(0, count)); //
    shell.delete();
  }

  static setHatchPattern(
    shellId: VisualizeJS.OdTvGeometryDataId,
    hatchPatternData: HatchPattern
  ) {
    const shell = shellId.openAsShell();
    const hatchPattern = HatchBuilder.createHatchPattern(hatchPatternData);
    shell.setHatchPattern(hatchPattern);

    // Create fill origins and set to shell. Do this after hatch pattern has been set to shell.
    const fillOrigins = new LibSingleton.lib.OdVectorPoint2d(); // Points number must be equal to face size * pattern lines count.
    fillOrigins.push_back([1.0, 0.0]); // 0 point
    fillOrigins.push_back([1.0, 0.0]); // 1 point

    shell.setFaceFillOrigins(fillOrigins);

    // Create fill directions and set to shell. Do this after hatch pattern has been set to shell.
    const fillDirections = new LibSingleton.lib.OdVectorVector2d(); // Vectors number must be equal to face size * pattern lines count.
    fillDirections.push_back([1.0, 0]); // 0 vector
    fillDirections.push_back([1.0, 0]); // 0 vector

    shell.setFaceFillDirections(fillDirections);
    // Set transparency
    const transparencyDef = new LibSingleton.lib.OdTvTransparencyDef();
    transparencyDef.setValue(1); //0-1
    shell.setFaceTransparencyViaRange(0, 1, transparencyDef);
  }

  static filterShells(
    entId: VisualizeJS.OdTvEntityId
  ): VisualizeJS.OdTvGeometryDataId[] {
    const geomIds = [];
    const ent = entId.openObject();
    const itr = ent.getGeometryDataIterator();
    for (; !itr.done(); itr.step()) {
      const geomId = itr.getGeometryData();
      if (
        geomId.getType() === LibSingleton.lib.OdTvGeometryDataType.kShell.value
      ) {
        geomIds.push(geomId);
      } else {
        geomId.delete();
      }
    }
    Toolbox.deleteAll([ent, itr]);
    return geomIds;
  }

  static isHatched(entId: VisualizeJS.OdTvEntityId): boolean {
    const geomIds = ShellBuilder.filterShells(entId);
    let isHatched = false;
    for (const geomId of geomIds) {
      const geom = geomId.openAsShell();
      const pattern = geom.getHatchPattern();
      if (!HatchBuilder.isEmptyPattern(pattern)) {
        isHatched = true;
        Toolbox.deleteAll([geom, pattern]);
        break;
      }
      Toolbox.deleteAll([geom, pattern]);
    }
    return isHatched;
  }

  static setPatternScale(entId: VisualizeJS.OdTvEntityId, scale: number) {
    const geomIds = ShellBuilder.filterShells(entId);
    for (const geomId of geomIds) {
      const geom = geomId.openAsShell();
      const pattern = geom.getHatchPattern();
      HatchBuilder.setScale(pattern, scale);
      geom.setHatchPattern(pattern);
      Toolbox.deleteAll([geom, pattern]);
    }
    Toolbox.deleteAll(geomIds);
  }
}
