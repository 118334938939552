<script setup lang="ts">
import { computed } from "vue";
import DRMenuItem from "./DRMenuItem.vue";
import type { MenuEntity } from "./types";
import { useRoute } from "vue-router";

defineProps<{
  menu: MenuEntity[];
}>();

const route = useRoute();

const activeRoute = computed(() => {
  return route.name ? (route.name as string) : undefined;
});
</script>

<template>
  <el-menu :default-active="activeRoute" router class="border-0 p-2">
    <DRMenuItem v-for="(item, id) in menu" :key="'menu-' + id" :item="item">
    </DRMenuItem>
  </el-menu>
</template>
