<script setup lang="ts">
import type { Layer } from "@/open-cloud/builders/LayerBuilder";
import { ref, toRaw } from "vue";
import { Logger } from "@/logger";
import { Facade } from "@/open-cloud/Facade";
import PerformanceModeToggler from "./PerformanceModeToggler.vue";

let layers = ref<Layer[]>([]);

function updateLayers() {
  layers.value = Facade.getLayers();
}

function toggleLayerVisibility(layer: Layer) {
  Logger.info(
    `LayerMenu.vue : Toggling current layer visibility ${JSON.stringify(
      layer
    )} `
  );
  const toggledVisibility = Facade.toggleLayerVisibility(toRaw(layer));
  if (toggledVisibility != layer.visibility) {
    Logger.info(
      `LayerMenu.vue : Successfully toggled ${JSON.stringify(layer)}`
    );
    layer.visibility = toggledVisibility;
  } else {
    Logger.info(`LayerMenu.vue : Failed to toggle ${JSON.stringify(layer)}`);
  }
}
</script>

<template>
  <div class="btn-group dropup">
    <button
      type="button"
      class="btn btn-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      data-bs-auto-close="false"
      aria-expanded="false"
      @click="updateLayers"
    >
      <i class="bi bi-layers"></i>
    </button>
    <ul
      class="dropdown-menu dropdown-menu-dark"
      style="max-height: 60vh; overflow-y: auto"
    >
      <li class="px-3 d-flex align-items-center justify-content-between">
        <span class="d-inline-block text-truncate"> Geler le fond </span>
        <PerformanceModeToggler />
      </li>
      <li>
        <hr class="dropdown-divider" />
      </li>
      <li>
        <h6 class="dropdown-header">Calques</h6>
      </li>
      <li v-for="layer in layers" :key="layer.name[0]">
        <div class="px-3 d-flex align-items-center justify-content-between">
          <span class="d-inline-block text-truncate" style="max-width: 150px">{{
            layer.name
          }}</span>
          <button
            @click="() => toggleLayerVisibility(layer)"
            type="button"
            class="btn btn-sm"
          >
            <i
              v-if="layer.visibility"
              class="bi bi-eye"
              style="color: white"
            ></i>
            <i v-else class="bi bi-eye-slash" style="color: white"></i>
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>
