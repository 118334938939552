import { TEMP_LAYER } from "../commands/DeleteEntities";
import { ColorDefBuilder, type Color } from "./ColorDefBuilder";
import type { DRLineWeightType } from "../types/lineweight.type";
import { Logger } from "@/logger";
import LibSingleton from "../LibSingleton";
import { LineweightBuilder } from "./LineweightBuilder";
import { MERGER_LAYER } from "../commands/TogglePerformanceMode";

export type Layer = {
  name: string; //string[] for Regexp
  visibility?: boolean;
  color: Color;
  lineweight?: DRLineWeightType;
};
export type Layers = Layer[];

export const DEFAULT_LAYER_NAME = "ZeroLayerName";

const DEFAULT_LAYER_THICKNESS: DRLineWeightType = 0;
//const THRESHOLD_DIST_LW = 100000;

export class LayerBuilder {
  static getLayers(): Layer[] {
    const layers: Layer[] = [];
    const layerItr: VisualizeJS.OdTvLayersIterator =
      LibSingleton.viewer.getLayersIterator();
    for (; !layerItr.done(); layerItr.step()) {
      const layerId = layerItr.getLayer();
      const layer = layerId.openObject();
      const name = layer.getName();
      // Don't return internal temp layer
      if (name != TEMP_LAYER.name && name != MERGER_LAYER.name) {
        layers.push({
          name: layer.getName(),
          visibility: layer.getVisible(),
          color: layer.getColor().getColor(),
          lineweight: layer.getLineWeight().getValue(),
        });
      }
      layerId.delete();
      layer.delete();
    }
    layerItr.delete();
    return layers;
  }

  static toggleLayerVisibility(layer: Layer): boolean {
    const layerId = LayerBuilder.findLayer(layer.name);
    if (layerId) {
      const layerPtr = layerId.openObject();
      let visibility = layerPtr.getVisible();
      layerPtr.setVisible(!visibility);
      layerPtr.setTotallyInvisible(visibility);
      visibility = layerPtr.getVisible();
      layerId.delete();
      layerPtr.delete();
      return visibility;
    } else {
      Logger.warn(
        `LayerBuilder.toggleLayerVisibility() : could not toggle visibility ${JSON.stringify(
          layer
        )}`
      );
      return false;
    }
  }

  // Finds a layer having the layername as name
  // layername can be a string or a Regexp
  // Returns the layerId of the first match or null if nothing was found
  static findLayer(layername: Layer["name"]): VisualizeJS.OdTvLayerId | null {
    const iter: VisualizeJS.OdTvLayersIterator =
      LibSingleton.viewer.getLayersIterator();
    for (; !iter.done(); iter.step()) {
      const layerId = iter.getLayer();
      const layer = layerId.openObject();
      const name = layer.getName();
      layer.delete();
      if (layername && typeof layername === "string" && layername == name) {
        iter.delete();
        return layerId;
      } else if (
        layername &&
        typeof layername !== "string" &&
        new RegExp(layername[0], layername[1]).test(name)
      ) {
        iter.delete();
        return layerId;
      }
    }
    iter.delete();
    return null;
  }

  static setProperties(layerId: VisualizeJS.OdTvLayerId, props: Layer) {
    const layer = layerId.openObject();
    if (typeof props.name === "string") {
      layer.setName(props.name);
    }
    if (props.visibility != undefined) {
      layer.setVisible(props.visibility);
      layer.setTotallyInvisible(!props.visibility);
    }
    if (props.color) LayerBuilder.setColor(layerId, props.color);
    LayerBuilder.setLineweight(layerId, props.lineweight);
    layer.delete();
  }

  static putLayer(props: Layer) {
    let layerId = LayerBuilder.findLayer(props.name);
    if (layerId == null) {
      layerId = LibSingleton.viewer.createLayer(
        props.name
      ) as VisualizeJS.OdTvLayerId; // ugly type
      this.setProperties(layerId, props);
    } else {
      this.setProperties(layerId, props);
    }
    layerId.delete();
  }

  // Sets the colordef of the entity based on color param
  // color must be a string as "R,G,B"
  static setColor(layerId: VisualizeJS.OdTvLayerId, color: string) {
    const layer = layerId.openObject();
    const colorDef = layer.getColor();
    ColorDefBuilder.setColor(colorDef, color);
    layer.setColor(colorDef);
    colorDef.delete();
    layer.delete();
  }

  static setLineweight(
    layerId: VisualizeJS.OdTvLayerId,
    lw = DEFAULT_LAYER_THICKNESS
  ) {
    const layer = layerId.openObject();
    const lwDef = layer.getLineWeight();
    LineweightBuilder.setLineweight(lwDef, lw);
    layer.setLineWeight(lwDef);
    lwDef.delete();
    layer.delete();
  }

  static logLayersProps() {
    const iter: VisualizeJS.OdTvLayersIterator =
      LibSingleton.viewer.getLayersIterator();
    for (; !iter.done(); iter.step()) {
      const layer = iter.getLayer();
      LayerBuilder.logLayerProps(layer);
      layer.delete();
    }
    iter.delete();
  }

  static logLayerProps(layerId: VisualizeJS.OdTvLayerId) {
    const layer = layerId.openObject();
    Logger.debug("layername: ", layer.getName());
    const colorDef = layer.getColor();
    ColorDefBuilder.logProps(colorDef);
    colorDef.delete();
    layer.delete();
  }
}
