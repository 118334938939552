import { EntityBuilder } from "../builders/EntityBuilder";
import { LayerBuilder, type Layer } from "../builders/LayerBuilder";
import Command from "./Command";
import LibSingleton from "../LibSingleton";
import { Logger } from "@/logger";
import { ModelBuilder } from "../builders/ModelBuilder";
export type DeleteParams = {
  handle: string;
  layername: Layer["name"];
}[];

export const TEMP_LAYER: Layer = {
  name: "__DR_TEMP",
  visibility: false,
  color: "255,0,0",
};

export default class DeleteEntities extends Command<DeleteParams> {
  private _tempLayer?: VisualizeJS.OdTvLayerId;
  // reset entities in their original layer
  execute(params: DeleteParams, shouldStore = true): void {
    const handles = params.map((param) => param.handle);
    EntityBuilder.setLayerByHandles(handles, TEMP_LAYER.name);
    super.execute(params, shouldStore);
    this._viewer.update();
  }
  _reexecute(params: DeleteParams) {
    const handles = params.map((param) => param.handle);
    EntityBuilder.setLayerByHandles(handles, TEMP_LAYER.name);
    this._viewer.update();
  }
  _unexecute(params: DeleteParams) {
    for (const param of params) {
      EntityBuilder.setLayerByHandle(param.handle, param.layername);
    }
    this._viewer.update();
  }
  _delete(params: DeleteParams): void {
    const handles = params.map((param) => param.handle);
    EntityBuilder.removeByHandlesIfTemp(handles);
  }

  clearTempLayer() {
    const model = ModelBuilder.getModel("ACTIVE");
    const itr = model.getEntitiesIterator();
    for (; !itr.done(); itr.step()) {
      const entity = itr.getEntity();
      if (EntityBuilder.isInTempLayer(entity)) {
        ModelBuilder.removeEntity(entity, "ACTIVE");
      }
      entity.delete();
    }
    itr.delete();
  }

  createTempLayer() {
    Logger.info(
      `DRViewer.createTempLayer : creating temp layer ${TEMP_LAYER.name}`
    );
    LayerBuilder.putLayer(TEMP_LAYER);
    this._tempLayer = LibSingleton.viewer.findLayer(TEMP_LAYER.name);
  }

  static log() {
    const model = ModelBuilder.getModel("ACTIVE");
    const itr = model.getEntitiesIterator();
    for (; !itr.done(); itr.step()) {
      const entity = itr.getEntity();
      if (EntityBuilder.isInTempLayer(entity)) {
        Logger.debug(EntityBuilder.logEntityProperties(entity));
      }
      entity.delete();
    }
    itr.delete();
  }

  dispose(): void {
    this.clearTempLayer();
    this._tempLayer?.delete();
    this._tempLayer = undefined;
  }
}
