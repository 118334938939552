import { EntityBuilder, type EntityProps } from "../builders/EntityBuilder";
import type { Coords } from "@/open-cloud/draggers/draggers.type";
import Toolbox from "../builders/ODAToolbox";
import {
  isCoord,
  isOdtvPoint,
  type pointArray,
} from "@/open-cloud/types/oda.types";
import type { DRViewer } from "../DRViewer";
import { TextBuilder, type TextData } from "../builders/TextBuilder";
import { ModelBuilder } from "../builders/ModelBuilder";

const MEASURE_LINE_DEFAULT_PROPS: EntityProps = {
  color: "125,125,125",
  transparency: {
    edge: 0.6,
  },
};

const LABEL_HEIGHT = 15; //px

export default class MeasureDragger {
  viewer: DRViewer;
  measureLines: VisualizeJS.OdTvEntityId[];
  constructor(viewer: DRViewer) {
    this.viewer = viewer;
    this.measureLines = [];
  }

  get textHeight() {
    return Toolbox.screenDistanceToWorld(LABEL_HEIGHT);
  }

  /**
   * This method draws a line with the length displayed
   * @param startPoint in WCS
   * @param endPoint in WCS
   */
  drawMeasureLine(
    startPoint: Coords,
    endPoint: Coords,
    props?: EntityProps
  ): void;
  drawMeasureLine(
    startPoint: pointArray,
    endPoint: pointArray,
    props?: EntityProps
  ): void;
  drawMeasureLine(
    startPoint: pointArray | Coords,
    endPoint: pointArray | Coords,
    props = MEASURE_LINE_DEFAULT_PROPS
  ): void {
    // @ts-ignore
    const MUPModel = ModelBuilder.findModel("MUP");
    const entityId = MUPModel.appendEntity("measurement");
    this.measureLines.push(entityId);
    const entity = entityId.openObject();
    if (isOdtvPoint(startPoint) && isOdtvPoint(endPoint)) {
      const line = entity.appendPolyline(startPoint.concat(endPoint));
      this.drawLabel(startPoint, endPoint, entityId);
      line.delete();
    } else if (isCoord(startPoint) && isCoord(endPoint)) {
      this.drawMeasureLine(
        Toolbox.coord2Point(startPoint),
        Toolbox.coord2Point(endPoint)
      );
    } else {
      throw new TypeError(
        `cannot draw measurement if inputs are not Coord or OdtvPoints`
      );
    }

    props.textsize = this.textHeight;

    EntityBuilder.setProperties(entityId, props);
    entity.delete();
    MUPModel.delete();
  }

  drawLabel(
    startPoint: pointArray,
    endPoint: pointArray,
    entityId: VisualizeJS.OdTvEntityId
  ) {
    const length = Toolbox.computeDistance2D(startPoint, endPoint);
    const center = Toolbox.computeCenter2D(startPoint, endPoint);
    const data: TextData = {
      refpoint: center,
      message: length.toFixed(2),
    };
    TextBuilder.addText(entityId, data);
  }

  clearMeasureLines() {
    const MUPModel = ModelBuilder.findModel("MUP");
    for (const measureLine of this.measureLines) {
      MUPModel.removeEntity(measureLine);
      measureLine.delete();
    }
    this.measureLines = [];
    MUPModel.delete();
  }

  setColor(color: [number, number, number, number]) {
    MEASURE_LINE_DEFAULT_PROPS.color = color.slice(0, 3).toString();
  }
}
