<script setup lang="ts">
import { useDrawingStore } from "@/stores/fileStore";
import { storeToRefs } from "pinia";
import UploadItem from "@/components/files/UploadList/UploadItem.vue";
import { computed } from "vue";

const { trackers } = storeToRefs(useDrawingStore());
const { removeTracker } = useDrawingStore();

const activeTrackers = computed(() => {
  return trackers.value.filter(({ conversionStatus }) => !!conversionStatus);
});
</script>

<template>
  <div
    v-if="!!activeTrackers.length"
    class="position-fixed bottom-0 end-0 m-3 p-3 bg-white shadow-lg rounded border border-1 border-grey-700"
    style="max-width: 400px"
  >
    <div class="d-flex gap-4 align-items-start">
      <div
        class="mt-1 position-relative d-flex align-items-center justify-content-center bg-light rounded-circle p-2"
      >
        <el-icon size="1.5rem"><i class="bi bi-cloud-arrow-up"></i></el-icon>
        <div
          class="spinner-border text-dark position-absolute"
          style="width: 3rem; height: 3rem"
          role="status"
        ></div>
      </div>
      <div class="d-flex flex-column gap-1">
        <span class="fw-bold"> Traitement en cours </span>
        <span style="font-size: 0.8rem" class="text-black-50"
          >Un ou plusieurs dessins importés sont en cours de traitement. Ils
          apparaitrons dans votre liste une fois le traitement terminé.</span
        >
      </div>
    </div>
    <el-collapse class="mt-3">
      <el-collapse-item>
        <template #title>
          <span class="fw-normal" style="font-size: 0.8rem">{{
            `Détails (${activeTrackers.length} ${
              activeTrackers.length > 1 ? "fichiers" : "fichier"
            })`
          }}</span>
        </template>
        <div class="d-flex">
          <TransitionGroup
            tag="ul"
            id="files-list"
            class="list-group w-100"
            name="fade"
          >
            <UploadItem
              v-for="tracker in activeTrackers"
              :tracker="tracker"
              :key="tracker.fileName"
              @remove="() => removeTracker(tracker.id, tracker.odaId)"
            />
          </TransitionGroup>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
  <!-- </template> -->
</template>

<style lang="scss">
.el-collapse {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.el-collapse-item__wrap {
  border-bottom: 0 !important;
}
.el-collapse-item__header {
  border-bottom: 0 !important;
  height: 1.8rem !important;
}
</style>
