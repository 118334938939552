<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import type { FileStoreDrawingFile } from "@/stores/fileStore";
import EditedInfo from "./EditedInfo.vue";
import DownloadDwgBtn from "./DownloadDwgBtn.vue";
import DownloadVsfxBtn from "./DownloadVsfxBtn.vue";
import SelectCheck from "./SelectCheck.vue";
import MakeLocalBtn from "./MakeLocalBtn.vue";
import { ref } from "vue";
import ArchiveBtn from "./ArchiveBtn.vue";
import { useLongPressTooltip } from "@/components/composable/useLongPressTooltip";
import { makeLocal } from "./downloadFile";
import { Logger } from "@/logger";
import { useToast } from "@/components/composable/useToast";
import { useDeviceState } from "@/stores/DeviceState";

const { refHook } = useLongPressTooltip({ pressExactTarget: true });
const device = useDeviceState();

const props = defineProps<{
  drawing: FileStoreDrawingFile;
}>();

const router = useRouter();
const { showDanger } = useToast();
let isMakingLocal = ref(false);

const outlineColor = computed(() => {
  if (props.drawing.isLocal) return "success";
  else return "primary";
});

async function open() {
  Logger.info(
    `DrawingItem.vue : opening ${props.drawing.name} ${props.drawing.id}`
  );
  if (props.drawing.isLocal) {
    router.push({
      name: "canvas",
      params: { drawingId: props.drawing.id },
    });
  } else if (device.isOnline) {
    await makeVsfxLocal();
    router.push({
      name: "canvas",
      params: { drawingId: props.drawing.id },
    });
  }
}

async function makeVsfxLocal() {
  isMakingLocal.value = true;
  try {
    await makeLocal(props.drawing);
  } catch (e) {
    Logger.error(
      `DrawingItem.vue : could not make drawing local ${
        props.drawing.id
      }, ${JSON.stringify(e)}`
    );
    showDanger("Erreur lors du chargement depuis le serveur");
    throw e;
  } finally {
    isMakingLocal.value = false;
  }
}
</script>

<template>
  <li
    @click="() => open()"
    class="cursor-pointer list-group-item d-flex justify-content-between ps-0 align-items-center gap-2 bg-white"
    ref="refHook"
    data-bs-content="Ouvrez et éditez les plans en appuyant sur la ligne de la liste.
                    <br>A l'ouverture, le plan est automatiquement téléchargé et disponible hors-ligne. Les modifications sont stockées en local.
                    <br>La synchronisation des modifications avec le serveur se fait lorsque l'app est connectée à internet."
    data-bs-title="Plan"
  >
    <SelectCheck :drawingid="drawing.id" :selected="drawing.isSelected" />

    <el-icon size="1.5rem" class="text-dark me-2"
      ><i class="bi bi-image-fill"></i
    ></el-icon>

    <div class="flex-grow-1 text-truncate">
      {{ drawing.name }}
    </div>
    <div>
      <!-- small size menu-->
      <div class="d-sm-block d-md-none dropdown">
        <button
          class="btn dropdown-toggle"
          :class="'btn-outline-' + outlineColor"
          type="button"
          data-bs-auto-close="true"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          @click.prevent.stop
        >
          <i class="bi bi-three-dots" />
        </button>
        <ul class="dropdown-menu p-1" @click.stop.prevent>
          <li class="dropdown-item">
            <EditedInfo :drawing="drawing" />
          </li>
          <li class="dropdown-item">
            <MakeLocalBtn
              :drawing="drawing"
              :spinning="isMakingLocal"
              @click.stop="makeVsfxLocal()"
            />
          </li>
          <li class="dropdown-item">
            <DownloadDwgBtn :drawing="drawing" />
          </li>
          <li class="dropdown-item">
            <DownloadVsfxBtn :drawing="drawing" />
          </li>
          <li class="dropdown-item">
            <ArchiveBtn :drawing="drawing" />
          </li>
        </ul>
      </div>
      <!-- big size menu-->
      <div class="d-none d-sm-none d-md-flex align-items-center">
        <EditedInfo class="me-1" :drawing="props.drawing" />
        <MakeLocalBtn
          class="me-1"
          @click.prevent.stop="makeVsfxLocal()"
          :drawing="props.drawing"
          :spinning="isMakingLocal"
        />
        <DownloadDwgBtn class="me-1" @click.stop.prevent :drawing="drawing" />
        <DownloadVsfxBtn class="me-1" @click.stop.prevent :drawing="drawing" />
        <ArchiveBtn @click.stop.prevent :drawing="drawing" />
      </div>
    </div>
  </li>
</template>
