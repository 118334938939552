import type { DRViewer } from "../DRViewer";
import AddEntities from "./AddEntities";
import DeleteEntities from "./DeleteEntities";
import ReplaceEntities from "./ReplaceEntities";
import TransformEntities from "./TransformEntities";
import History from "./History";
import SetEntityProperties from "./SetEntityProperties";
import EditTextsCommand from "./EditText";
import AddLeader from "./AddLeader";
import TogglePerformanceMode from "./TogglePerformanceMode";
import { Logger } from "@/logger";

export default class CommandFactory {
  history: History;
  addEntities: AddEntities;
  deleteEntities: DeleteEntities;
  replaceEntities: ReplaceEntities;
  transformEntities: TransformEntities;
  setEntityProperties: SetEntityProperties;
  togglePerformanceMode: TogglePerformanceMode;
  editTexts: EditTextsCommand;
  addLeaders: AddLeader;
  constructor(viewer: DRViewer) {
    this.addEntities = new AddEntities(viewer);
    this.history = new History(viewer);
    this.deleteEntities = new DeleteEntities(viewer);
    this.replaceEntities = new ReplaceEntities(viewer);
    this.transformEntities = new TransformEntities(viewer);
    this.setEntityProperties = new SetEntityProperties(viewer);
    this.editTexts = new EditTextsCommand(viewer);
    this.addLeaders = new AddLeader(viewer);
    this.togglePerformanceMode = new TogglePerformanceMode(viewer);
  }

  dispose() {
    Logger.info(`CommandFactory : disposing commands`);
    this.togglePerformanceMode.dispose();
    this.deleteEntities.dispose();
  }
}
