import { defineStore } from "pinia";
import type { File as OdaFile } from "@inweb/client";
import type { DRDrawing, GeometryStatus } from "@/repositories/domain.types";
import { computed, ref } from "vue";
import { liveQuery } from "dexie";
import { v4 as uuidV4 } from "uuid";
import { remove } from "lodash";
import { syncer } from "@/repositories/Syncer";
import { drawingRepository } from "@/repositories/drawing.repository";
import { DrawingDecoder } from "@/open-cloud/drawing.decoder";
import { Monitor } from "@/utils/monitor";
import { useDeviceState } from "@/stores/DeviceState";
import {
  removeAccents,
  removeSpecialCharsForOcsFiles,
} from "@/utils/misc.utils";
import { Logger } from "@/logger";
import { LocalStorageKey } from "@/utils/local-storage";

type TrackerStatus = null | "uploading" | GeometryStatus;

type StoredTrackingFile = {
  id: string;
  name: string;
  size: number;
};

export type UploadedFileDataHook = (fileData: any) => void;
export type UploadProgressHook = (progress: number) => void;

export type Tracker = {
  id: string;
  fileName: string;
  fileSize: number;
  progress: number;
  progressDisplay: number;
  conversionStatus: TrackerStatus;
  odaId: string | null;
};

export type FileStoreDrawingFile = Omit<DRDrawing, "lastRevision"> & {
  isLocal: boolean;
  hasDraft: boolean;
  isSynced: boolean;
  isSelected: boolean;
  lastRevisionEditedAt?: Date;
  syncStatus?: string;
};

function renameFile(originalFile: File, newName: string) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const getStoredTrackingFiles = (): StoredTrackingFile[] => {
  const item = localStorage.getItem("trackingFiles");
  return item ? JSON.parse(item) : [];
};

const tranformStoredFilesToTrackers = (
  files: StoredTrackingFile[]
): Tracker[] => {
  return files.map(
    (file): Tracker => ({
      id: uuidV4(),
      fileName: file.name,
      fileSize: file.size,
      progress: 1,
      progressDisplay: 100,
      conversionStatus: null,
      odaId: file.id,
    })
  );
};

export const useDrawingStore = defineStore("fileStore", () => {
  const drawings = ref<FileStoreDrawingFile[]>([]);
  const areAllSynced = computed(() => drawings.value.every((e) => e.isSynced));

  const deviceState = useDeviceState();

  async function syncDrawingStat() {
    if (!deviceState.isOnline) return;
    try {
      const start = performance.now();
      Logger.info(`filestore.syncDrawingStat : autosync`);
      await syncer.autoSync();
      const end = performance.now();
      Logger.info(`filestore.syncDrawingStat : syncing took ${end - start} ms`);
    } catch (e) {
      Logger.error(`fileStore.syncDrawingStat() : error ${JSON.stringify(e)}`);
    }
  }

  const syncMonitor = new Monitor(syncDrawingStat, { intervalInMs: 5000 });

  const trackers = ref<Tracker[]>([]);

  const calcGlobalFileStatus = (file: OdaFile): GeometryStatus => {
    const geometryStatus = file.status.geometry.state as GeometryStatus;
    const propertiesStatus = file.status.properties.state as GeometryStatus;

    if (geometryStatus === "done" && propertiesStatus === "done") return "done";

    const asArray: GeometryStatus[] = [geometryStatus, propertiesStatus];

    if (asArray.includes("failed")) return "failed";
    // if one is none (job not wanted), we take the status of the other one
    // if (asArray.includes("none")) {
    //   return asArray.find((e) => e !== "none") || "failed";
    // }
    if (
      asArray.includes("waiting") ||
      asArray.includes("inprogress") ||
      asArray.includes("none")
    ) {
      return "inprogress";
    }

    throw new Error(
      `unexpected file status '${geometryStatus}, ${propertiesStatus}' for file ${file.id}`
    );
  };

  const checkOdaFile = async (
    data: {
      odaId?: string;
      odaFile?: OdaFile;
    },
    onDone: (odaFile: OdaFile) => boolean
  ) => {
    if (!data.odaId && !data.odaFile) throw new Error("param is required");

    let odaFile = data.odaFile;
    if (!odaFile) {
      odaFile = await syncer.getOdaFile({ id: data.odaId });
    }

    odaFile = await odaFile.checkout();

    const success = onDone(odaFile);
    if (!success)
      setTimeout(() => {
        checkOdaFile(
          {
            odaFile,
          },
          onDone
        );
      }, 3000);
  };

  const removeFromLocalStorage = (id: string) => {
    const items = localStorage.getItem(LocalStorageKey.TRACKING_FILES);
    const storedFiles: StoredTrackingFile[] = items ? JSON.parse(items) : [];
    const newStoredFiles = storedFiles.filter((f) => f.id !== id);
    localStorage.setItem(
      LocalStorageKey.TRACKING_FILES,
      JSON.stringify(newStoredFiles)
    );
  };

  const removeTracker = (id: string, odaId?: string | null) => {
    remove(trackers.value, { id });
    if (odaId) removeFromLocalStorage(odaId);
  };

  const startTrackerStatusCheckout = async (
    trackerId: string,
    odaId: string
  ) => {
    await checkOdaFile(
      {
        odaId,
      },
      (odaFile) => {
        const newStatus = calcGlobalFileStatus(odaFile);

        const tracker = trackers.value.find((f) => f.id === trackerId);
        if (!tracker?.odaId) return false;

        if (!tracker.conversionStatus && newStatus === "done") {
          removeTracker(trackerId, odaId);
          return true;
        }

        tracker.conversionStatus = newStatus;

        if (newStatus === "done") {
          setTimeout(() => {
            removeTracker(trackerId, odaId);
          }, 5000);
          return true;
        }
        if (newStatus === "failed") return true;

        return false;
      }
    );
  };

  const initTrackers = () => {
    const storedFiles = getStoredTrackingFiles();
    const initialTrackers = tranformStoredFilesToTrackers(storedFiles);
    trackers.value = initialTrackers;
    initialTrackers.map(({ id, odaId }) => {
      if (odaId) startTrackerStatusCheckout(id, odaId);
    });
  };

  initTrackers();

  const drawingsObservable = liveQuery<DRDrawing[]>(() =>
    drawingRepository.fetchAllByNewest()
  );

  drawingsObservable.subscribe({
    next: (result) => {
      drawings.value = result.map((e) => {
        const drawing = drawings.value.find((drawing) => drawing.id === e.id);
        const options = {
          isSelected: drawing?.isSelected,
        };
        return DrawingDecoder.fromDomainToFileStore(e, options);
      });
    },
  });

  const setTrackerProgress = (trackerId: string, progress: number) => {
    const tracker = trackers.value.find((f) => f.id === trackerId);
    if (!tracker) return;

    if (tracker.progress >= 1) return; // means we have already been there

    // update the tracker, for display and tracking if we already finished it once
    tracker.progress = progress;
    tracker.progressDisplay = Math.round(progress * 100);

    // it's the first time that we process upload completed
    if (progress >= 1) {
      tracker.conversionStatus = "inprogress";
    }
  };

  const saveFileOnLocalStorage = (file: StoredTrackingFile) => {
    const item = localStorage.getItem(LocalStorageKey.TRACKING_FILES);
    const storedFiles: any[] = item ? JSON.parse(item) : [];

    storedFiles.push(file);

    localStorage.setItem(
      LocalStorageKey.TRACKING_FILES,
      JSON.stringify(storedFiles)
    );
  };

  const setTrackerFileData = (trackerId: string, odaId: string) => {
    const tracker = trackers.value.find((f) => f.id === trackerId);
    if (!tracker) return;
    tracker.odaId = odaId;

    saveFileOnLocalStorage({
      id: odaId,
      name: tracker.fileName,
      size: tracker.fileSize,
    });
    startTrackerStatusCheckout(tracker.id, odaId);
  };

  const uploadFiles = async (files: File[]) => {
    const renamedFiles = files.map((file) => {
      const splitted = file.name.split(".");
      const extension = splitted.pop();
      const normalized = removeSpecialCharsForOcsFiles(
        removeAccents(splitted.join("."))
      );

      return renameFile(file, `${normalized}.${extension}`);
    });

    const newTrackers = Array.from(renamedFiles).map(
      (file): Tracker => ({
        id: uuidV4(),
        fileName: file.name,
        fileSize: file.size,
        progress: 0,
        progressDisplay: 0,
        conversionStatus: "uploading" as TrackerStatus,
        odaId: null,
      })
    );

    trackers.value = trackers.value.concat(newTrackers);

    const progressHooks: {
      onProgress: UploadProgressHook;
      onFileUploaded: UploadedFileDataHook;
    }[] = newTrackers.map((tracker) => {
      return {
        onProgress: (progress: number) =>
          setTrackerProgress(tracker.id, progress),
        onFileUploaded: ({ id: odaId }: any) => {
          setTrackerFileData(tracker.id, odaId);
        },
      };
    });

    await syncer.uploadManyDwg(renamedFiles, progressHooks);
    return;
  };
  function startAutoSync() {
    syncMonitor.start();
  }
  function stopAutoSync() {
    syncMonitor.stop();
  }

  function clearDrawings() {
    drawings.value = [];
    trackers.value = [];
  }

  async function archiveDrawing(drawingId: number) {
    const drawing = drawings.value.find((drawing) => drawing.id === drawingId);
    if (drawing) {
      if (!drawing.isSynced) {
        Logger.info(
          `fileStore.ts : can't archive a drawing that is not synced ${drawing.id}`
        );
        throw new Error("DRAWING NOT SYNC");
      } else {
        await syncer.archiveDrawingAndRefreshList(drawing.id);
        drawing.isSelected = false;
      }
    } else {
      Logger.warn(
        `fileStore.ts : can't archive drawing ${drawingId}, drawing is not in the list`
      );
      throw new Error("DRAWING NOT FOUND");
    }
  }

  function toggleDrawing(drawingId: number) {
    const drawing = drawings.value.find((drawing) => drawing.id === drawingId);
    if (drawing) {
      drawing.isSelected = !drawing.isSelected;
    }
  }

  return {
    drawings,
    trackers,
    uploadFiles,
    removeTracker,
    stopAutoSync,
    startAutoSync,
    areAllSynced,
    clearDrawings,
    archiveDrawing,
    toggleDrawing,
  };
});

export const useGetArchivedDrawings = () => {
  const isLoading = ref(false);
  const error = ref(null);
  const getArchivedDrawings = async () => {
    isLoading.value = true;
    try {
      const drawings = await syncer.fetchArchivedDrawings();
      isLoading.value = false;

      return drawings.map((d) => DrawingDecoder.fromDomainToFileStore(d));
    } catch (e) {
      Logger.error(
        `fileStore.getArchivedDrawings() : error ${JSON.stringify(e)}`
      );
      isLoading.value = false;
      error.value = e;
    }
  };

  return { isLoading, error, getArchivedDrawings };
};
