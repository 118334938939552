import { DRViewer } from "@/open-cloud/DRViewer";
import { DraggerEvents } from "../draggers/draggers.type";
import { ModelBuilder } from "./ModelBuilder";
import Toolbox from "@/open-cloud/builders/ODAToolbox";
import OdaGeometryUtils from "@/open-cloud/builders/odaGeometry.utils";
import { EntityBuilder, type EntityProps } from "./EntityBuilder";
import type { DeleteParams } from "../commands/DeleteEntities";
import type { ReplaceParams } from "../commands/ReplaceEntities";
import { HistoryEvents } from "../commands/History";
import LibSingleton from "../LibSingleton";
import TogglePerformanceMode from "../commands/TogglePerformanceMode";

export class SelectionSetBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
    viewer.addEventListener(HistoryEvents.UndoEnded, () => {
      this.resetSelectionSet();
    });

    viewer.addEventListener(HistoryEvents.RedoEnded, () => {
      this.resetSelectionSet();
    });
  }

  private static _iterateCurrentSelection(
    fn: (entity: VisualizeJS.OdTvEntityId) => void
  ) {
    if (DRViewer.selectionSet && DRViewer.selectionSet.numItems() > 0) {
      const itr = DRViewer.selectionSet.getIterator();
      Toolbox.iterateBase(itr, fn, (itr) => itr.getEntity());
      itr.delete();
    }
  }
  static highlightSelectionSet(
    selectionSet: VisualizeJS.OdTvSelectionSet | null,
    isHighlight: boolean
  ) {
    if (selectionSet && selectionSet.numItems() != 0) {
      const aView = LibSingleton.viewer.activeView;
      aView.highlightAll(selectionSet, isHighlight);
    }
  }

  static logSelectionSetProperties(
    selectionSet: VisualizeJS.OdTvSelectionSet | null
  ) {
    if (selectionSet) {
      const fn = (entityId: VisualizeJS.OdTvEntityId) => {
        EntityBuilder.logEntityProperties(entityId);
        entityId.delete();
      };
      const itr = selectionSet.getIterator();
      Toolbox.iterateBase(itr, fn, (itr) => itr.getEntity());
    }
  }
  // Instead of deleting selection, we should move it to another Model
  deleteSelection() {
    const params: DeleteParams = [];

    const fn = (entityId: VisualizeJS.OdTvEntityId) => {
      params.push({
        handle: EntityBuilder.getHandle(entityId),
        layername: EntityBuilder.getLayerName(entityId),
      });

      entityId.delete();
    };

    SelectionSetBuilder._iterateCurrentSelection(fn);

    SelectionSetBuilder.highlightSelectionSet(DRViewer.selectionSet, false);
    this.viewer.commandFactory.deleteEntities.execute(params);

    DRViewer.selectionSet = null;

    this.viewer.emitEvent({ type: DraggerEvents.SelectionDeleted });
    this.viewer.update();
  }

  static duplicateEntity(
    aModel: VisualizeJS.TvModel,
    copyTypeOf: VisualizeJS.OdTvEntityId
  ): VisualizeJS.OdTvEntityId {
    const createdEntityId = ModelBuilder.cloneEntity(copyTypeOf, aModel);

    const extent = EntityBuilder.getAgnosticExtent(copyTypeOf);
    const { min, max } = EntityBuilder.decodeExtent(extent);
    const xLength = max[0] - min[0];
    const yLength = max[1] - min[1];
    extent.delete();
    const shiftVector: VisualizeJS.Vector3 = [xLength / 3, -yLength / 3, 0];
    const matrix0 = OdaGeometryUtils.getTranslationMatrix(shiftVector);
    const matrix1 =
      EntityBuilder.getModelingMatrix(copyTypeOf).preMultBy(matrix0);
    EntityBuilder.setModelingMatrix(createdEntityId, matrix1);
    matrix0.delete();
    matrix1.delete();
    return createdEntityId;
  }

  copySelection() {
    const aModel = ModelBuilder.findModel("ACTIVE");

    const duplicatedIds: Array<VisualizeJS.OdTvEntityId> | null = [];

    SelectionSetBuilder._iterateCurrentSelection((entityId) => {
      const createdId = SelectionSetBuilder.duplicateEntity(aModel, entityId);
      duplicatedIds.push(createdId);
    });

    if (duplicatedIds.length > 0) {
      const params =
        this.viewer.commandFactory.addEntities.getParams(duplicatedIds);
      this.viewer.commandFactory.addEntities.execute(params);
      // selection newly created entities
      this.selectEntitiesManually(duplicatedIds);
      // to autoselect only the first entity
      // this.selectEntityManually(duplicatedIds[0]);
    }

    this.viewer.emitEvent({ type: DraggerEvents.SelectionDuplicated });
    this.viewer.update();
    aModel.delete();
  }

  setSelectionProperty(props: EntityProps) {
    if (!DRViewer.selectionSet) return;
    const oldSet = DRViewer.selectionSet;
    const itr = oldSet.getIterator();
    DRViewer.selectionSet = new LibSingleton.lib.OdTvSelectionSet();
    const params: ReplaceParams = [];
    // unhighlight entities
    const aView = LibSingleton.viewer.activeView;
    aView.highlightAll(oldSet, false);
    const model = ModelBuilder.findModel("ACTIVE");
    for (; !itr.done(); itr.step()) {
      const oldId = itr.getEntity();
      // clone entity
      const entId = ModelBuilder.cloneEntity(oldId, model);
      if (entId) {
        // set properties of clone
        EntityBuilder.setProperties(entId, props);
        // add it to selection set
        DRViewer.selectionSet.appendEntity(entId);
        // push to replace params
        params.push({
          old: {
            handle: EntityBuilder.getHandle(oldId),
            layername: EntityBuilder.getLayerName(oldId),
          },
          replacer: {
            handle: EntityBuilder.getHandle(entId),
            layername: EntityBuilder.getLayerName(entId),
          },
        });
        oldId.delete();
        entId.delete();
      }
    }
    itr.delete();
    // Rehighlight selection set
    aView.highlightAll(DRViewer.selectionSet, true);
    // send replace params to history
    this.viewer.commandFactory.replaceEntities.execute(params);
    // send events to listeners (update grid)
    this.viewer.emitEvent({ type: DraggerEvents.SetSelectionProperty });
  }

  resetSelectionSetWithoutEmitEvent() {
    // un-highlight old selection set
    SelectionSetBuilder.highlightSelectionSet(DRViewer.selectionSet, false);
    // override selection set
    DRViewer.selectionSet = new LibSingleton.lib.OdTvSelectionSet();
  }

  resetSelectionSet() {
    this.resetSelectionSetWithoutEmitEvent();
    this.viewer.emitEvent({ type: DraggerEvents.SelectionEnded });
    this.viewer.update();
  }

  selectEntityManually(entityId: VisualizeJS.OdTvEntityId) {
    this.selectEntitiesManually([entityId]);
  }

  selectEntitiesManually(entities: VisualizeJS.OdTvEntityId[]) {
    this.resetSelectionSetWithoutEmitEvent();

    for (const entityId of entities) {
      DRViewer.selectionSet?.appendEntity(entityId);
    }
    SelectionSetBuilder.highlightSelectionSet(DRViewer.selectionSet, true);
    this.viewer.emitEvent({ type: DraggerEvents.SelectionEnded });
  }
  // return the entity in selection set or null
  static getEntity(handle: string): VisualizeJS.OdTvEntityId | null {
    if (!DRViewer.selectionSet) return null;
    const itr = DRViewer.selectionSet.getIterator();
    for (; !itr.done(); itr.step()) {
      const entId = itr.getEntity();
      const entHandle = EntityBuilder.getHandle(entId);
      if (handle === entHandle) {
        itr.delete();
        return entId;
      } else {
        entId.delete();
      }
    }
    return null;
  }

  static pruneMerger(selectionSet: VisualizeJS.OdTvSelectionSet) {
    const itr = selectionSet.getIterator();
    for (; !itr.done(); itr.step()) {
      const entId = itr.getEntity();
      if (TogglePerformanceMode.isMerger(entId)) {
        selectionSet.removeEntity(entId);
      }
      entId.delete();
    }
    itr.delete();
  }
}
