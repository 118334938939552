import { v4 } from "uuid";
import OneWayEntityDragger from "./OneWayEntityDragger";
import { ModelBuilder } from "@/open-cloud/builders/ModelBuilder";

export default class BlockDragger extends OneWayEntityDragger {
  _updateFrame(): void {
    if (this.viewer.activeBlockId && this.endCornerWCS.length === 3) {
      const model: VisualizeJS.TvModel = ModelBuilder.findModel("MUP");

      if (this.shadowId) model.removeEntity(this.shadowId);

      this.shadowId = model.appendInsert(
        this.viewer.activeBlockId,
        "DR_Insert_" + v4()
      );

      model.delete();

      const entityPtr = this.shadowId.openObjectAsInsert();
      entityPtr.setPosition(this.endCornerWCS);
      entityPtr.delete();
      this._setNoteConfigProperties(this.shadowId);
    }
  }
}
