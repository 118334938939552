import { type DRViewer } from "./DRViewer";

export default class LibSingleton {
  private static _lib: typeof VisualizeJS | null = null;
  private static _viewer: VisualizeJS.Viewer | null = null;

  static get lib() {
    if (LibSingleton._lib != null) return LibSingleton._lib;
    else throw new Error(`LibSingleton : no lib`);
  }

  static get viewer() {
    if (LibSingleton._viewer != null) return LibSingleton._viewer;
    else throw new Error(`LibSingleton : no viewer`);
  }

  static init(viewer: DRViewer) {
    LibSingleton._lib = viewer.visLib();
    LibSingleton._viewer = viewer.visViewer();
  }
}
