<script setup lang="ts">
import { useDrawingStore } from "@/stores/fileStore";
import { useDeviceState } from "@/stores/DeviceState";
import { useToast } from "@/components/composable/useToast";
import { Logger } from "@/logger";
import { computed } from "vue";
import DRBtn from "../UI/DRBtn.vue";
import { useScreen } from "../composable/useResponsive";

const { showPrimary, showWarning } = useToast();

const deviceState = useDeviceState();

const drawingStore = useDrawingStore();

async function archiveSelection() {
  Logger.info(`FileOptions.vue : archiving selected drawing`);
  if (window.confirm("Êtes-vous sûr de vouloir archiver la sélection ?")) {
    let hasArchived = false;
    for (const drawing of drawingStore.drawings) {
      if (drawing.isSelected && drawing.isSynced) {
        try {
          drawingStore.archiveDrawing(drawing.id);
          hasArchived = true;
        } catch (e: any) {
          if (e.message === "DRAWING NOT SYNC") {
            showWarning(
              `Le plan ${drawing.name} n'est pas synchronisé et ne peut être archivé. Attendez la synchronisation.`
            );
          } else {
            throw e;
          }
        }
        drawing.isSelected = false;
      }
    }
    if (hasArchived) showPrimary("Les plans sélectionnés ont été archivés.");
  }
}

const selectedCount = computed(() => {
  return drawingStore.drawings.filter((drawing) => drawing.isSelected).length;
});

const isAllSelected = computed(() => {
  return selectedCount.value === drawingStore.drawings.length;
});

const hasSelected = computed(() => {
  return selectedCount.value > 0;
});

const isIndeterminate = computed(() => {
  return hasSelected.value && !isAllSelected.value;
});

const handleSelectAllChange = (
  value: boolean | undefined | string | number
) => {
  drawingStore.drawings.forEach((drawing) => {
    drawing.isSelected = !!value;
  });
};

const screen = useScreen();
</script>

<template>
  <div
    v-if="drawingStore.drawings.length"
    class="d-flex align-items-center gap-3"
  >
    <div class="ps-4">
      <el-checkbox
        color="info"
        v-model="isAllSelected"
        :indeterminate="isIndeterminate"
        @change="handleSelectAllChange"
        :label="`${screen.lt.md ? 'Sélect.' : 'Sélectionner'} (${
          selectedCount || 0
        } sur ${drawingStore.drawings.length})`"
      />
    </div>

    <DRBtn
      v-if="hasSelected"
      :disabled="!deviceState.isOnline"
      @click="archiveSelection"
      :label="screen.lt.sm ? '' : 'Archiver'"
      icon="bi bi-archive"
      type="outline"
    />
  </div>
</template>
