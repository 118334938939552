<script setup lang="ts">
import { useDrawingStore } from "@/stores/fileStore";
import { Logger } from "@/logger";
import DraggableUploader from "../UI/DraggableUploader.vue";

// const uuid = Math.random().toString();
const drawingStore = useDrawingStore();

async function uploadFile(files?: File[]) {
  if (files) {
    Logger.info(`AddFile.vue : uploading files, ${files.length}`);
    try {
      drawingStore.uploadFiles(files);
    } catch (e) {
      Logger.error(`AddFile.vue : could not upload file, ${JSON.stringify(e)}`);
      throw e;
    }
  } else {
    Logger.info(`AddFile.vue : No file upaloaded`);
  }
}
</script>

<template>
  <DraggableUploader
    description="Seul les fichiers .dwg, .vsf et .vsfx sont acceptés"
    :extensions="['dwg', 'vsf', 'vsfx']"
    @files="uploadFile"
  />
</template>
