import type { DRLineWeightType } from "@/open-cloud/types/lineweight.type";
import { Logger } from "@/logger";
import LibSingleton from "../LibSingleton";

export class LineweightBuilder {
  static logLineweight(entId: VisualizeJS.OdTvEntityId) {
    const lwDef = LineweightBuilder.getLineweightDef(entId);
    Logger.debug("Line weight type: ", lwDef.getType());
    Logger.debug("Line weight Inherited: ", lwDef.getInheritedLineWeight());
    Logger.debug("Line weight value: ", lwDef.getValue());
    lwDef.delete();
  }

  static getLineweightDef(
    objId: VisualizeJS.OdTvEntityId | VisualizeJS.OdTvLayerId,
    type = LibSingleton.lib.GeometryTypes.kAll
  ): VisualizeJS.OdTvLineWeightDef {
    const obj = objId.openObject();
    const lw = obj.getLineWeight(type);
    obj.delete();
    return lw;
  }

  static setLineweight(
    lwDef: VisualizeJS.OdTvLineWeightDef,
    lw: DRLineWeightType
  ) {
    if (lw == "kDefault") {
      lwDef.setDefault();
    } else if (lw == "kInherited") {
      lwDef.setInherited(LibSingleton.lib.InheritedAttribute.kByLayer); // FIXME : should be parametered with default value
    } else {
      lwDef.setValue(lw);
    }
  }
}
