import type { Theme } from "@/components/layouts/navbar/Themes";
import { DRViewer } from "./DRViewer";
import type { OdaBinaryFormat } from "@/repositories/domain.types";
import type { NoteConfig } from "@/stores/UserState";
import { type DraggerConfig, DraggerEvents } from "./draggers/draggers.type";
import { LayerBuilder, type Layer } from "./builders/LayerBuilder";
import { TextStyleBuilder, type Textstyle } from "./builders/TextStyleBuilder";
import { Logger } from "@/logger";
import { debounce } from "lodash";
import { photoMarkers } from "@/blocks/photomarker";
import { EntityBuilder, type EntityProps } from "./builders/EntityBuilder";
import { BlockBuilder, type BlockConfig } from "./builders/BlockBuilder";
import { PhotoMarker, type PhotoMarkerShape } from "./photoMarker";
import { DR_ENTITY_NAME } from "./draggers/DRBaseDragger";
import { TextBuilder, type TextData } from "./builders/TextBuilder";
import type { EditTextParam } from "./commands/EditText";
import { ModelBuilder, type Model } from "./builders/ModelBuilder";
import { InsertBuilder } from "./builders/InsertBuilder";
import LibSingleton from "./LibSingleton";
import { LeaderBuilder } from "./builders/LeaderBuilder";
import { DeviceBuilder } from "./builders/DeviceBuilder";
import { SelectionSetBuilder } from "./builders/SelectionSetBuilder";

type DrawingOpenListener = (event: CustomEvent) => void;
type ActionListener = (event: CustomEvent) => void;

export class Facade {
  static _viewer?: DRViewer;
  static _openListeners: DrawingOpenListener[] = [];
  static _actionListeners: ActionListener[] = [];
  static isDrawingOpen = false;

  static createViewer(onlyStylusOn: boolean) {
    Facade._viewer = new DRViewer({ onlyStylusOn: onlyStylusOn });
  }

  static async initializeViewer(
    canvas: HTMLCanvasElement,
    onProgress: (event: ProgressEvent) => void
  ) {
    await Facade._viewer?.initialize(canvas, onProgress);
  }

  static async openVsf(
    drawingId: number,
    vsf: ArrayBuffer,
    format: OdaBinaryFormat,
    isHatchActive: boolean
  ) {
    await Facade._viewer?.openVsf(drawingId, vsf, format);
    await Facade._viewer?.initVsfViewer(isHatchActive);
    Facade.isDrawingOpen = true;
  }

  static async saveDraft() {
    await Facade._viewer?.save();
  }

  static regenAll() {
    Facade._viewer?.visViewer().regenAll();
  }

  static dispose() {
    Logger.debug("Facade disposing...");
    Facade._viewer?.removeAllListeners();
    Facade._openListeners = [];
    Facade._actionListeners = [];
    Facade._viewer?.dispose();
    Facade._viewer = undefined;
  }

  static setViewerTheme(theme: Theme) {
    const viewer = Facade._viewer;
    if (!viewer) return;
    DeviceBuilder.setBackgroundColor(theme.bgColor, theme.overrideColor);

    viewer.ruler.setColor(theme.toolColor);
    viewer.ruler.drawRulers();

    viewer.measureDragger.setColor(theme.toolColor);
  }

  static setActiveDragger(config: DraggerConfig) {
    const viewer = Facade._viewer;
    if (!viewer) return;
    viewer.activateDragger(config);
  }

  static activateNoteConfig(config: NoteConfig) {
    const viewer = Facade._viewer;
    if (!viewer) return;
    viewer.activeNoteConfig = config;
    viewer.putLayer();
  }

  static setSelectionProperty(props: EntityProps) {
    const viewer = Facade._viewer;
    if (!viewer) return;
    viewer.selectionSetBuilder.setSelectionProperty(props);
    viewer.update();
  }

  static deleteSelection() {
    const viewer = Facade._viewer;
    if (!viewer) return;
    viewer.selectionSetBuilder.deleteSelection();
  }

  static undo() {
    Facade._viewer?.commandFactory.history.undo();
  }

  static redo() {
    Facade._viewer?.commandFactory.history.redo();
  }

  static escape() {
    Facade._viewer?.emitEvent(new CustomEvent(DraggerEvents.Escape));
    Facade.resetSelectionSet();
  }

  static async copySelection() {
    Facade._viewer?.selectionSetBuilder.copySelection();
  }

  static setActiveLayers(layers: Layer[]) {
    const viewer = Facade._viewer;
    if (!viewer) return;
    viewer.activeLayerConfigs = layers;
  }

  static on(type: string, listener: DrawingOpenListener) {
    const viewer = Facade._viewer;
    if (!viewer) return;
    viewer.addEventListener(type, listener);
    Facade._openListeners.push(listener);
  }

  static updateOrCreateTextStyle(textstyle: Textstyle) {
    const viewer = Facade._viewer;
    if (!viewer) return;
    TextStyleBuilder.createTextStyle(textstyle);
  }

  static getLayers(): Layer[] {
    return LayerBuilder.getLayers() || [];
  }

  static toggleLayerVisibility(layer: Layer) {
    const viewer = Facade._viewer;
    if (!viewer) return;
    const visibility = LayerBuilder.toggleLayerVisibility(layer);
    viewer.update();
    return visibility;
  }

  static setActiveText(text: string) {
    const viewer = Facade._viewer;
    if (!viewer) return;
    viewer.activeText = text;
  }

  static getLastMarkerCount(): number | null {
    return PhotoMarker.lastCount ?? null;
  }

  static setActivePhotoMarkerNumber(text: string) {
    if (!Facade._viewer) return;
    Facade._viewer.activePhotoMarkerNumber = text;
  }

  static resetSelectionSet() {
    Facade._viewer?.selectionSetBuilder.resetSelectionSet();
  }

  static getTextstyles() {
    return TextStyleBuilder.getTextStyles() ?? [];
  }

  static setPhotoMarker(config: BlockConfig) {
    if (Facade._viewer) {
      Facade._viewer.activePhotoMarker = config;
      Facade._viewer.activePhotoMarkerId = BlockBuilder.create(config, true);
    }
  }
  static setPhotoMarkerShape(shape: PhotoMarkerShape) {
    switch (shape) {
      case "large_arrow":
        Facade.setPhotoMarker(photoMarkers[0]);
        break;
      case "circle":
        Facade.setPhotoMarker(photoMarkers[1]);
        break;
      case "thin_arrow":
        Facade.setPhotoMarker(photoMarkers[2]);
        break;
      default:
        break;
    }
  }

  static setActiveBlock(block: BlockConfig | null) {
    if (Facade._viewer) {
      Facade._viewer.activeBlock = block;
      if (block != null) {
        Facade._viewer.activeBlockId = BlockBuilder.create(block, true);
      }
    }
  }

  static setOrigin(coordWCS: [number, number, number]) {
    Facade._viewer?.emitEvent(
      new CustomEvent(DraggerEvents.OriginSet, {
        detail: {
          coordinates: coordWCS,
        },
      })
    );
  }
  static setCloseContour(close: boolean) {
    if (Facade._viewer) Facade._viewer.closeContour = close;
  }

  static setSmoothen(smoothen: boolean) {
    if (Facade._viewer) Facade._viewer.smoothen = smoothen;
  }
  static setLabel(label: boolean) {
    if (Facade._viewer) Facade._viewer.label = label;
  }

  static zoomExtents() {
    if (Facade._viewer) {
      LibSingleton.viewer.zoomExtents();
      // zoom extent can take time but does not block instruction flow.
      // it does not return a promise either.
      // only isRunningAnimation() tells if viewer is done zooming.
      // we then use a debounce approach
      const debounceEmit = debounce(() => {
        if (Facade._viewer) {
          const viewer = LibSingleton.viewer;
          if (viewer.isRunningAnimation()) {
            debounceEmit();
          } else {
            Facade._viewer.emit({ type: DraggerEvents.AutoPanZoomEnded });
          }
        }
      }, 500);
      debounceEmit();
    }
  }
  /**
   * Calculate the center, min, max points of the BB in Canvas coordinate system
   * of the selected entity with handle same as argument
   * @param handle
   * @returns [center, min, max] as Point3[]
   */
  static getSelectedEntityExtentInCanvas(handle: string): VisualizeJS.Point3[] {
    if (!Facade._viewer) return [];
    const entId = SelectionSetBuilder.getEntity(handle);
    if (entId) {
      const extent = EntityBuilder.getCanvasExtent(entId);
      entId.delete();
      return extent;
    } else {
      return [];
    }
  }

  static getText(handle: string): string {
    if (!Facade._viewer) return "";
    const entId = SelectionSetBuilder.getEntity(handle);
    let content = "";
    if (entId && EntityBuilder.countTextGeometries(entId) != 0) {
      content = EntityBuilder.getTextContent(entId);
    }
    return content;
  }

  static editText(handle: string, newContent: string, oldContent?: string) {
    if (!Facade._viewer) return [];
    if (!oldContent) {
      const entId = SelectionSetBuilder.getEntity(handle);
      if (!entId) {
        throw new Error(`Facade.editText : can't find entity in selectionset`);
      }
      oldContent = EntityBuilder.getTextContent(entId);
      entId?.delete();
    }
    const param: EditTextParam = {
      handle,
      content: newContent,
      oldContent,
    };
    Facade._viewer.commandFactory.editTexts.execute([param]);
  }
  // add a text entity at the position (html elemnt relative position) with the config
  static addText(
    message: string,
    offset: VisualizeJS.Point2,
    config: NoteConfig,
    storeToHistory = true, // store it to allow undo redo
    autoselect = false, // select the entity at the end
    modelPseudo: Model["pseudo"] = "ACTIVE"
  ): string {
    if (!Facade._viewer) return "";
    const entId = ModelBuilder.createEntity(DR_ENTITY_NAME, modelPseudo);
    const viewer = LibSingleton.viewer;
    const positionWCS = viewer.screenToWorld(...offset);
    const data: TextData = {
      message,
      refpoint: positionWCS,
    };
    TextBuilder.addText(entId, data);
    EntityBuilder.setProperties(entId, config.props);
    const handle = EntityBuilder.getHandle(entId);
    if (storeToHistory) {
      const layername = EntityBuilder.getLayerName(entId);
      Facade._viewer.commandFactory.addEntities.execute([
        {
          handle: handle,
          layername: layername,
        },
      ]);
    }
    if (autoselect) {
      Facade._viewer.selectionSetBuilder.selectEntityManually(entId);
    }
    Facade._viewer.update();
    entId.delete();
    return handle;
  }

  static removeEntity(handle: string, modelPseudo: Model["pseudo"] = "ACTIVE") {
    if (!Facade._viewer) return "";
    const model = ModelBuilder.findModel(modelPseudo);
    const entId = ModelBuilder.getEntityByHandle(handle, modelPseudo);
    if (entId && !entId.isNull()) {
      model.removeEntity(entId);
      entId?.delete();
    }
    model.delete();
  }

  // some entities like text, photomarkers are scalable using scale props
  static isScalable(handle: string): boolean {
    const entId = SelectionSetBuilder.getEntity(handle);
    if (!entId || entId.isNull() || !Facade._viewer) return false;
    return (
      InsertBuilder.isInsert(entId) || // inserts
      (EntityBuilder.countTextGeometries(entId) != 0 && //entity containing text but not leaders
        !LeaderBuilder.isLeader(entId))
    );
  }

  static isEditable(handle: string): boolean {
    const entId = SelectionSetBuilder.getEntity(handle);
    if (!entId || entId.isNull() || !Facade._viewer) return false;
    return (
      EntityBuilder.countTextGeometries(entId) != 0 &&
      !LeaderBuilder.isLeader(entId)
    );
  }

  static getSelectedEntityScale(handle: string): number | undefined {
    const entId = SelectionSetBuilder.getEntity(handle);
    if (!entId || entId.isNull() || !Facade._viewer) return undefined;
    if (entId.getType() === 1) {
      return EntityBuilder.computeScale(entId);
    } else if (entId.getType() === 2) {
      return InsertBuilder.getScale(entId);
    } else {
      throw new Error("unknow entity");
    }
  }
  static setPerformanceModeOn(b: boolean) {
    // reset selection set in case native entities are in it
    // FIX ME : we could also remove natives ones as an improvement
    if (b) Facade._viewer?.selectionSetBuilder.resetSelectionSet();
    Facade._viewer?.commandFactory.togglePerformanceMode.execute(
      { status: b },
      false // don't store perf mode changes to history
    );
  }
}
