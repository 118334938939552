import { computed, onMounted, ref } from "vue";
import { useRegisterSW } from "virtual:pwa-register/vue";

function getBrowserInfo() {
  const userAgent = navigator.userAgent;
  let browserName = "Unknown";

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    if (/CriOS/.test(userAgent)) {
      browserName = "Chrome";
    } else if (/FxiOS/.test(userAgent)) {
      browserName = "Firefox";
    } else if (/EdgiOS/.test(userAgent)) {
      browserName = "Edge";
    } else if (/OPiOS/.test(userAgent)) {
      browserName = "Opera";
    } else if (/Mobile.*Safari/.test(userAgent)) {
      browserName = "Safari";
    }
  } else {
    if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Firefox";
    } else if (userAgent.indexOf("SamsungBrowser") > -1) {
      browserName = "Samsung Browser";
    } else if (
      userAgent.indexOf("Opera") > -1 ||
      userAgent.indexOf("OPR") > -1
    ) {
      browserName = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
      browserName = "Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
      browserName = "Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Safari";
    }
  }
  return browserName;
}

function isMobileOrTablet() {
  const ua = navigator.userAgent;
  return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Tablet|iPad/.test(
    ua
  );
}

function isPWAInstalled() {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    !!(navigator as any).standalone
  );
}
function isRecommendedBrowser() {
  if (isPWAInstalled()) return true;

  const recommendedBrowsers = ["Chrome", "Edge", "Opera"];
  const browser = getBrowserInfo();

  return recommendedBrowsers.includes(browser);
}

async function persistStorage() {
  if (!navigator.storage) return;
  const isPersisted = await navigator.storage.persisted();
  if (!isPersisted) {
    await navigator.storage.persist();
  }
}

export const usePwaController = () => {
  const { needRefresh, updateServiceWorker } = useRegisterSW({
    onRegistered(r: any) {
      r &&
        setInterval(() => {
          r.update();
        }, 1000 * 60);
    },
  });

  function reload() {
    updateServiceWorker();
  }

  const shouldChangeBrowser = ref(false);
  const shouldChangeBrowserAlert = ref(false);
  const shouldInstallPWA = ref(false);
  const shouldInstallPWAAlert = ref(false);
  const isDesktop = computed(() => !isMobileOrTablet());

  onMounted(async () => {
    const isRecommended = await isRecommendedBrowser();
    shouldChangeBrowser.value = !isRecommended;
    shouldChangeBrowserAlert.value = !isRecommended;

    if (isPWAInstalled()) {
      await persistStorage();
    } else {
      shouldInstallPWA.value = true;
      shouldInstallPWAAlert.value = true;
    }
  });

  return {
    shouldChangeBrowserAlert,
    shouldChangeBrowser,
    shouldInstallPWAAlert,
    shouldInstallPWA,
    needRefresh,
    reload,
    getBrowserInfo,
    isDesktop,
  };
};
