<script setup lang="ts">
import type { FileStoreDrawingFile } from "@/stores/fileStore";
import DrawingItem from "./DrawingItem.vue";

import { computed, ref } from "vue";
const props = defineProps<{
  drawings: FileStoreDrawingFile[];
}>();

const currentPage = ref(1);
const pageSize = ref(10);

const pageDrawings = computed(() => {
  return props.drawings.slice(
    (currentPage.value - 1) * pageSize.value,
    currentPage.value * pageSize.value
  );
});
</script>

<template>
  <ul id="files-list" class="list-group w-100">
    <DrawingItem
      v-for="drawing in pageDrawings"
      :drawing="drawing"
      :key="drawing.id"
    />
  </ul>

  <el-pagination
    class="pt-3"
    v-model:current-page="currentPage"
    v-model:page-size="pageSize"
    :page-sizes="[5, 10, 20, 50]"
    layout="sizes, prev, pager, next"
    :total="drawings.length"
  />
</template>
